import { Utils } from '@antv/graphin';
var getSchemaGraph = function getSchemaGraph(schemaData, config) {
  var colorMap = new Map();
  var nodesConfigMap = new Map();
  var edgesConfigMap = new Map();
  var nodesConfig = config.nodes,
    edgesConfig = config.edges;
  nodesConfig.forEach(function (c) {
    var key = JSON.stringify(c.expressions);
    nodesConfigMap.set(key, c);
  });
  edgesConfig.forEach(function (c) {
    var key = JSON.stringify(c.expressions);
    edgesConfigMap.set(key, c);
  });
  var nodes = schemaData.nodes.map(function (n, index) {
    var nodeType = n.nodeType,
      nodeTypeKeyFromProperties = n.nodeTypeKeyFromProperties,
      id = n.id;
    var expressions = [{
      name: nodeTypeKeyFromProperties,
      operator: 'eql',
      value: nodeType
    }];
    var key = JSON.stringify(expressions);
    var prev = nodesConfigMap.get(key);
    var color = prev && prev.props && prev.props.color || '#ddd';
    return {
      id: nodeType || id,
      style: {
        keyshape: {
          size: 20,
          fill: color,
          fillOpacity: 1
        },
        label: {
          value: nodeType
        }
      }
    };
  });
  var edges = schemaData.edges.map(function (e) {
    var edgeType = e.edgeType,
      edgeTypeKeyFromProperties = e.edgeTypeKeyFromProperties,
      sourceNodeType = e.sourceNodeType,
      targetNodeType = e.targetNodeType,
      source = e.source,
      target = e.target;
    var expressions = [{
      name: edgeTypeKeyFromProperties,
      operator: 'eql',
      value: edgeType
    }];
    var key = JSON.stringify(expressions);
    var prev = edgesConfigMap.get(key);
    var color = prev && prev.props && prev.props.color || '#ddd';
    return {
      source: sourceNodeType || source,
      target: targetNodeType || target,
      style: {
        keyshape: {
          stroke: color
        },
        label: {
          value: e.edgeType,
          fill: color,
          fontSize: 12,
          background: {
            // 设置背景的填充色
            fill: '#fff',
            // 设置圆角
            radius: 4,
            // 设置border，即 stroke
            stroke: color
          }
        }
      }
    };
  });
  return {
    nodes: nodes,
    edges: Utils.processEdges(edges)
  };
};
export default getSchemaGraph;