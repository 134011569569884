import { common } from '@antv/gi-sdk';
import $i18n from '../i18n'; // 格式化表格标题
export var formatFileName = function formatFileName(name) {
  var formattedName = $i18n.get({
    id: 'gi-assets-algorithm.src.utils.csv.ExportDataCsv',
    dm: '导出数据.csv'
  });
  if (name) {
    if (name.indexOf('.csv') > 0) {
      formattedName = name;
    } else {
      formattedName = name + '.csv';
    }
  }
  return formattedName;
};
// 格式化导出数据
export var transformCSVData = function transformCSVData(originData, columns) {
  var title = [];
  var titleForKey = [];
  var exportData = {};
  if (columns && columns.length > 0) {
    columns.forEach(function (column) {
      titleForKey.push(column.dataIndex);
      title.push(column.title);
    });
  }
  if (originData && originData.length > 0) {
    exportData = {
      data: originData,
      title: title,
      titleForKey: titleForKey
    };
  }
  return exportData;
};
// 导出csv数据
export var exportCSV = function exportCSV(config, fileName) {
  // showPureNumber 判断是否导出纯数字  目的：兼容不希望以科学计数展示的数字
  var data = config.data,
    titleForKey = config.titleForKey,
    title = config.title,
    _config$showPureNumbe = config.showPureNumber,
    showPureNumber = _config$showPureNumbe === void 0 ? false : _config$showPureNumbe;
  var csvText = [];
  if (title && title.length > 0) {
    csvText.push(title.join(',') + '\n');
  }
  for (var i = 0; i < data.length; i++) {
    var temp = [];
    for (var j = 0; j < titleForKey.length; j++) {
      var value = data[i][titleForKey[j]] || '';
      // 如果为数字则加上\t的后缀，避免以科学计数展示
      var prefix = isNaN(+value) || showPureNumber ? '' : '\t';
      temp.push("\"".concat(prefix).concat(value, "\""));
    }
    csvText.push(temp.join(',') + '\n');
  }
  common.createDownload(new Blob(["\uFEFF" + csvText.join('')], {
    type: 'text/csv'
  }), fileName);
  return csvText;
};
export default {
  formatFileName: formatFileName,
  transformCSVData: transformCSVData,
  exportCSV: exportCSV
};