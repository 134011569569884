function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import $i18n from '../../i18n';
var modes = ['TableMode', 'MapMode'];
var registerMeta = function registerMeta(context) {
  var _context$GIAC_CONTENT = context.GIAC_CONTENT_ITEMS,
    GIAC_CONTENT_ITEMS = _context$GIAC_CONTENT === void 0 ? [] : _context$GIAC_CONTENT,
    GIAC_ITEMS = context.GIAC_ITEMS;
  var modeItems = _toConsumableArray(GIAC_CONTENT_ITEMS).filter(function (item) {
    return modes.includes(item.value);
  });
  var schema = {
    GI_CONTAINER: {
      title: $i18n.get({
        id: 'advance.components.ModeSwitch.registerMeta.IntegratedComponents',
        dm: '集成组件'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        mode: 'multiple'
      },
      enum: modeItems,
      default: []
    },
    // ...metas,
    placement: {
      title: $i18n.get({
        id: 'advance.components.ModeSwitch.registerMeta.PlacementOrientation',
        dm: '放置方位'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: [{
          value: 'LT',
          label: $i18n.get({
            id: 'advance.components.ModeSwitch.registerMeta.TopLeftTop',
            dm: '左上 / top'
          })
        }, {
          value: 'RT',
          label: $i18n.get({
            id: 'advance.components.ModeSwitch.registerMeta.TopRightRight',
            dm: '右上 / right'
          })
        }, {
          value: 'LB',
          label: $i18n.get({
            id: 'advance.components.ModeSwitch.registerMeta.LowerLeftLeft',
            dm: '左下 / left'
          })
        }, {
          value: 'RB',
          label: $i18n.get({
            id: 'advance.components.ModeSwitch.registerMeta.BottomRightBottom',
            dm: '右下 / bottom'
          })
        }]
      },
      default: 'LT'
    },
    offset: {
      title: $i18n.get({
        id: 'advance.components.ModeSwitch.registerMeta.OffsetDistance',
        dm: '偏移距离'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Offset',
      default: [16, 8]
    }
  };
  return schema;
};
export default registerMeta;