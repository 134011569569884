import { extra } from '@antv/gi-sdk';
import info from './info';
var deepClone = extra.deepClone,
  GIAC_METAS = extra.GIAC_METAS;
var metas = deepClone(GIAC_METAS);
metas.GIAC.properties.GIAC.properties.title.default = info.name;
metas.GIAC.properties.GIAC.properties.icon.default = info.icon;
metas.GIAC.properties.GIAC.properties.tooltip.default = info.desc;
metas.GIAC.properties.GIAC.properties.isShowTooltip.default = true;
var registerMeta = function registerMeta() {
  return metas;
};
export default registerMeta;