export var debounce = function debounce(func, timer) {
  var flag;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    clearTimeout(flag);
    flag = window.setTimeout(function () {
      func.apply(void 0, args);
    }, timer || 50);
  };
};
export default {
  debounce: debounce
};