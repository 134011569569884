import { extra } from '@antv/gi-sdk';
import info from './info';
var deepClone = extra.deepClone,
  GIAC_CONTENT_METAS = extra.GIAC_CONTENT_METAS;
var metas = deepClone(GIAC_CONTENT_METAS);
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.title.default = info.name;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.icon.default = info.icon;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.tooltip.default = info.desc;
var registerMeta = function registerMeta() {
  var schema = Object.assign({
    url: {
      title: 'Socket URL',
      type: 'string',
      'x-component': 'Input',
      'x-decorator': 'FormItem',
      default: 'ws://localhost:9003'
    }
  }, metas);
  return schema;
};
export default registerMeta;