function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
export var getStyles = function getStyles(height, placement, position) {
  var _position = _slicedToArray(position, 2),
    x = _position[0],
    y = _position[1];
  if (placement === 'top') {
    return {
      container: {
        // position: 'relative',
        // top: `${height}px`,
        // height: `calc( 100% - ${height}px )`,
      },
      sheetbar: {
        position: 'absolute',
        display: 'flex',
        top: "".concat(y, "px"),
        left: "".concat(x, "px"),
        right: '0px',
        height: "".concat(height + 1, "px"),
        lineHeight: "".concat(height + 1, "px"),
        background: "var(--background-color)",
        zIndex: 300,
        borderBottom: "1px solid var(--border-color)"
      }
    };
  }
  return {
    container: {
      // position: 'relative',
      // top: `unset`,
      // height: `calc( 100% - ${height}px )`,
    },
    sheetbar: {
      display: 'flex',
      position: 'absolute',
      bottom: "".concat(y, "px"),
      left: "".concat(x, "px"),
      right: '0px',
      height: "".concat(height + 1, "px"),
      lineHeight: "".concat(height + 1, "px"),
      background: "var(--background-color)",
      zIndex: 300,
      borderTop: "1px solid var(--border-color)"
    }
  };
};