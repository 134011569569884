import $i18n from '../../i18n';
var info = {
  id: 'FitCenterView',
  name: $i18n.get({
    id: 'basic.components.FitCenterView.info.AdaptiveCentering',
    dm: '自适应居中'
  }),
  desc: $i18n.get({
    id: 'basic.components.FitCenterView.info.AdjustTheCanvasToDisplay',
    dm: '调整画布，使其自适应居中展示'
  }),
  icon: 'icon-fit-center',
  cover: 'http://xxxx.jpg',
  category: 'canvas-interaction',
  type: 'GIAC',
  docs: 'https://www.yuque.com/antv/gi/wc317ftgwwk3fwny'
};
export default info;