function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var defaultProps = {
  donut: [],
  label: ['id'],
  donutColors: '',
  size: undefined
};
/** 数据映射函数  需要根据配置自动生成*/
var transform = function transform(nodes, nodeConfig) {
  try {
    /** 解构配置项 */
    var props = Object.assign({}, defaultProps, nodeConfig.props);
    var dountKeys = props.donut,
      labelKey = props.label,
      donutColors = props.donutColors,
      SIZE = props.size;
    var colorKeys = ['#61DDAA', '#F08BB4', '#65789B'];
    if (typeof donutColors === 'string') {
      colorKeys = donutColors.split(',') || ['#61DDAA', '#F08BB4', '#65789B'];
    }
    var donutColorMap = dountKeys.reduce(function (acc, curr, index) {
      return Object.assign(Object.assign({}, acc), _defineProperty({}, curr, colorKeys[index]));
    }, {});
    var transNodes = nodes.map(function (node) {
      var id = node.id;
      var data = node.data || node;
      /**  构造 donutAttrs */
      var donutAttrs = dountKeys.reduce(function (acc, curr) {
        return Object.assign(Object.assign({}, acc), _defineProperty({}, curr, data[curr]));
      }, {});
      var donutSumCount = dountKeys.reduce(function (acc, curr) {
        return acc + data[curr];
      }, 16);
      var size = SIZE || Math.sqrt(donutSumCount) * 5;
      return {
        id: id,
        data: data,
        type: 'donut',
        label: data[labelKey],
        donutAttrs: donutAttrs,
        donutColorMap: donutColorMap,
        size: size,
        /** G6 */
        style: {
          lineWidth: 0
        },
        labelCfg: {
          position: 'bottom'
        }
      };
    });
    return transNodes;
  } catch (error) {
    console.error('parse transform error:', error);
    return nodes;
  }
};
export default transform;