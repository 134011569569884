function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export var stringify = function stringify(obj) {
  var placeholder = '____PLACEHOLDER____';
  var fns = [];
  var json = JSON.stringify(obj, function (key, value) {
    if (typeof value === 'function') {
      fns.push(value);
      return placeholder;
    }
    return value;
  }, 2);
  //@ts-ignore
  json = json.replace(new RegExp('"' + placeholder + '"', 'g'), function (_) {
    return fns.shift();
  });
  return 'export default ' + json + '';
};
/**
 *
 * @param componentAssets 服务端拿到的资产: Components
 * @param data 图数据
 * @returns
 */
var getMockServiceConfig = function getMockServiceConfig(componentAssets) {
  return Object.keys(componentAssets).map(function (key) {
    var component = componentAssets[key];
    if (!component) {
      return [];
    }
    var mockServices = component.mockServices;
    if (mockServices) {
      var sers = mockServices();
      return sers.map(function (c) {
        return {
          id: c.id,
          mode: 'MOCK',
          name: c.name || c.id,
          content: stringify(c.service)
        };
      });
    }
    return [];
  }).reduce(function (acc, curr) {
    return [].concat(_toConsumableArray(acc), _toConsumableArray(curr));
  }, []).filter(function (c) {
    return c;
  });
};
export default getMockServiceConfig;