import $i18n from '../i18n';
var info = {
  id: 'MapMode',
  name: $i18n.get({
    id: 'scene.src.MapMode.info.MapMode',
    dm: '地图模式'
  }),
  desc: $i18n.get({
    id: 'scene.src.MapMode.info.MapTheGeographicCoordinatesOf',
    dm: '将节点的地理坐标映射到地图上'
  }),
  icon: 'icon-global',
  cover: 'http://xxxx.jpg',
  category: 'scene-analysis',
  type: 'GIAC',
  docs: 'https://www.yuque.com/antv/gi/kbpiuu1inefxgdtg'
};
export default info;