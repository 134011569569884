export var LOOP_EDGE_STYLE = {
  keyshape: {
    hasArrow: true,
    customPoly: false,
    lineDash: [4, 4],
    opacity: 1,
    stroke: '#ddd'
  },
  animate: {
    visible: true,
    type: 'line-dash',
    repeat: true,
    duration: 8000
  }
};
export var ENTER_LOOP_EDGE_STYLE = {
  keyshape: {
    hasArrow: true,
    customPoly: false,
    lineDash: [4, 4],
    opacity: 1,
    stroke: 'rgba(245,166,35,1)'
  },
  animate: {
    visible: true,
    type: 'circle-running',
    dotColor: 'red',
    repeat: true,
    duration: 3000
  }
};
export var BASE_NODES_DATA = ". 1,jim\n. 2,kate\n. 3,lily\n. 4,lucy\n. 5,brown\n. 6,jack\n. 7,jackson";
export var BASE_EDGES_DATA = "- 1,2,0.2\n- 2,3,0.3\n- 3,4,0.2\n- 4,1,0.1\n- 4,5,0.1\n- 5,1,0.2\n- 5,6,0.1\n- 6,7,0.1";
export var PALETTES = {
  normal: ['orange', 'magenta', 'lime', 'yellow', 'red', 'green', 'volcano', 'geekblue', 'pink', 'cyan'],
  candies: ['rgb(251, 180, 174)', 'rgb(179, 205, 227)', 'rgb(204, 235, 197)', 'rgb(222, 203, 228)', 'rgb(254, 217, 166)', 'rgb(255, 255, 204)', 'rgb(229, 216, 189)', 'rgb(253, 218, 236)', 'rgb(242, 242, 242)']
};