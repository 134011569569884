import $i18n from '../../i18n';
var info = {
  id: 'Watermark',
  name: $i18n.get({
    id: 'basic.components.Watermark.info.Watermark',
    dm: '水印'
  }),
  desc: $i18n.get({
    id: 'basic.components.Watermark.info.AddWatermark',
    dm: '添加水印'
  }),
  cover: 'http://xxxx.jpg',
  category: 'system-interaction',
  icon: 'icon-placeholder',
  type: 'AUTO',
  services: ['WatermarkService'],
  docs: 'https://www.yuque.com/antv/gi/atogtq0417351bpq'
};
export default info;