import { extra } from '@antv/gi-sdk';
import info from './info';
import $i18n from '../../i18n';
var deepClone = extra.deepClone,
  GIAC_CONTENT_METAS = extra.GIAC_CONTENT_METAS;
var metas = deepClone(GIAC_CONTENT_METAS);
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.title.default = info.name;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.icon.default = info.icon;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.containerWidth.default = '400px';
var registerMeta = function registerMeta(_ref) {
  var schemaData = _ref.schemaData;
  var edgeProperties = schemaData.edges.reduce(function (acc, cur) {
    return Object.assign(Object.assign({}, acc), cur.properties);
  }, {});
  var options = Object.keys(edgeProperties).filter(function (key) {
    return edgeProperties[key] === 'number';
  }).map(function (e) {
    return {
      value: e,
      label: e
    };
  });
  return Object.assign({
    weightField: {
      title: $i18n.get({
        id: 'basic.components.Sankey.registerMeta.WeightMapping',
        dm: '权重映射'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: options,
      default: ''
    }
  }, metas);
};
export default registerMeta;