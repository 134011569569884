function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { template } from '@antv/gi-sdk';
import $i18n from '../i18n';
var pageLayout = {
  id: 'SegmentedLayout',
  name: $i18n.get({
    id: 'basic.src.templates.Simple.SegmentLayout',
    dm: '分段布局'
  }),
  props: {
    containers: [{
      id: 'GI_CONTAINER_SIDE',
      name: $i18n.get({
        id: 'basic.src.templates.Simple.SideContainer',
        dm: '侧边容器'
      }),
      required: true,
      GI_CONTAINER: ['FilterPanel'],
      display: true
    }]
  }
};
var components = [].concat(_toConsumableArray(template.components), [pageLayout]);
export var config = {
  nodes: template.nodes,
  edges: template.edges,
  layout: template.layout,
  components: components,
  pageLayout: pageLayout
};
export var activeAssetsKeys = {
  elements: [].concat(_toConsumableArray(config.nodes.map(function (n) {
    return n.id;
  })), _toConsumableArray(config.edges.map(function (e) {
    return e.id;
  }))),
  components: _toConsumableArray(components.map(function (c) {
    return c.id;
  })),
  layouts: ['Force2', 'Concentric', 'Dagre', 'FundForce', 'GraphinForce']
};
export default Object.assign({
  name: $i18n.get({
    id: 'basic.src.templates.Simple.MinimalistTemplate',
    dm: '极简模版'
  }),
  id: 'TP_SIMPLE',
  desc: $i18n.get({
    id: 'basic.src.templates.Simple.ThisTemplateIsAnOfficial',
    dm: '该模版是官方提供的极简模版，包含 {activeAssetsKeysComponentsLength} 个分析资产，提供常见的「交互分析」「筛选看数」等功能，页面布局上，画布展示空间较大，提供沉浸式分析体验'
  }, {
    activeAssetsKeysComponentsLength: activeAssetsKeys.components.length
  }),
  image: "/image/tp_simple.png",
  activeAssetsKeys: activeAssetsKeys
}, config);