import { extra, utils } from '@antv/gi-sdk';
import info from './info';
import $i18n from '../../i18n';
var deepClone = extra.deepClone,
  GIAC_CONTENT_METAS = extra.GIAC_CONTENT_METAS;
var metas = deepClone(GIAC_CONTENT_METAS);
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.title.default = info.name;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.icon.default = info.icon;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.tooltip.default = info.desc;
export default (function (_ref) {
  var services = _ref.services;
  var serviceOptions = utils.getServiceOptions(services, info.services[0]);
  return Object.assign({
    serviceId: {
      title: $i18n.get({
        id: 'basic.components.Save.registerMeta.SaveService',
        dm: '保存服务'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: serviceOptions
      },
      default: serviceOptions[0].value
    }
  }, metas);
});