import React from 'react';
import { useContext } from '@antv/gi-sdk';
export default (function () {
  var context = useContext();
  var removeNodes = React.useCallback(function (nodeIds) {
    var _a, _b;
    if (nodeIds.length === 0) {
      return;
    }
    var graphData = context.graph.save();
    var nodeMap = {};
    var nodes = (_a = graphData.nodes) === null || _a === void 0 ? void 0 : _a.filter(function (n) {
      var result = !nodeIds.includes(n.id);
      if (result) {
        nodeMap[n.id] = n;
      }
      return result;
    });
    var edges = (_b = graphData.edges) === null || _b === void 0 ? void 0 : _b.filter(function (edge) {
      return nodeMap[edge.source] && nodeMap[edge.target];
    });
    context.updateData(Object.assign(Object.assign({}, graphData), {
      nodes: nodes,
      edges: edges
    }));
  }, [context]);
  var removeEdges = React.useCallback(function (edgeIds) {
    var _a;
    if (edgeIds.length === 0) {
      return;
    }
    var graphData = context.graph.save();
    var edges = (_a = graphData.edges) === null || _a === void 0 ? void 0 : _a.filter(function (edge) {
      return !edgeIds.includes(edge.id);
    });
    context.updateData(Object.assign(Object.assign({}, graphData), {
      edges: edges
    }));
  }, [context]);
  return React.useMemo(function () {
    return {
      removeNodes: removeNodes,
      removeEdges: removeEdges
    };
  }, [removeNodes, removeEdges]);
});