function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
// 生成 switcher 所需要的 fields 结构
function generateSwitcherFields(updatedResult) {
  return {
    columns: {
      selectable: true,
      items: updatedResult.columns.items
    }
  };
}
// 生成 dataCfg fields 结构
function generateFields(updatedResult) {
  return {
    columns: updatedResult.columns.items.filter(function (c) {
      return c.checked !== false;
    }).map(function (c) {
      return c.id;
    })
  };
}
var useSwitcher = function useSwitcher(defaultFields) {
  var defaultSwitcherFields = {
    columns: {
      selectable: true,
      items: defaultFields.columns.map(function (item) {
        return {
          id: item
        };
      })
    }
  };
  var _useState = useState({
      fields: defaultFields,
      switcherFields: defaultSwitcherFields
    }),
    _useState2 = _slicedToArray(_useState, 2),
    state = _useState2[0],
    setState = _useState2[1];
  useEffect(function () {
    if (!isEqual(state.fields, defaultFields)) {
      setState(function (preState) {
        return Object.assign(Object.assign({}, preState), {
          fields: defaultFields,
          switcherFields: defaultSwitcherFields
        });
      });
    }
  }, [defaultFields]);
  var fields = state.fields,
    switcherFields = state.switcherFields;
  var onSwitch = function onSwitch(result) {
    setState(function (preState) {
      return Object.assign(Object.assign({}, preState), {
        fields: generateFields(result),
        switcherFields: generateSwitcherFields(result)
      });
    });
  };
  return {
    fields: fields,
    switcherFields: switcherFields,
    onSwitch: onSwitch
  };
};
export default useSwitcher;