function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { template } from '@antv/gi-sdk';
// 页面布局容器
import $i18n from '../i18n';
var pageLayout = {
  id: 'UadLayout',
  type: 'GICC_LAYOUT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.UpperAndLowerLayout',
    dm: '上下布局'
  }),
  props: {
    containers: [{
      id: 'GI_CONTAINER_TOP',
      GI_CONTAINER: ['GremlinQuery'],
      height: 251,
      padding: '0px 0px'
    }, {
      id: 'GI_CONTAINER_SIDE',
      GI_CONTAINER: ['JSONMode'],
      tabPosition: 'right'
    }]
  }
};
var addComponents = [{
  id: 'GremlinQuery',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.GremlinQuery',
    dm: 'Gremlin 查询'
  }),
  props: {
    serviceId: 'GraphScope/GremlinQuery',
    isShowPublishButton: false,
    isShowLimit: true,
    isShowTimeout: true,
    saveTemplateServceId: 'GI/PublishTemplate',
    initialValue: 'g.V().limit(10)',
    height: 166,
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: 'Gremlin',
      isShowIcon: true,
      icon: 'icon-query',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '350px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'TableMode',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.TableMode',
    dm: '表格模式'
  }),
  props: {
    enableCopy: true,
    isSelectedActive: true,
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: $i18n.get({
        id: 'basic.src.templates.Query.TableMode',
        dm: '表格模式'
      }),
      isShowIcon: true,
      icon: 'icon-table',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'basic.src.templates.Query.ShowNodesAndEdgesIn',
        dm: '将画布中的节点和边以表格形式展示'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '400px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'JSONMode',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.CodeMode',
    dm: '代码模式'
  }),
  props: {
    theme: 'rjv-default',
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: $i18n.get({
        id: 'basic.src.templates.Query.CodeMode',
        dm: '代码模式'
      }),
      isShowIcon: true,
      icon: 'icon-table',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'basic.src.templates.Query.ShowDataInCode',
        dm: '将数据以代码形式展示'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '400px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'SideTabs',
  type: 'GICC',
  name: $i18n.get({
    id: 'basic.src.templates.Query.Sidebar',
    dm: '侧边栏'
  }),
  props: {
    GI_CONTAINER: ['FilterPanel', 'NodeImportance', 'CommunityDetection', 'PatternMatch'],
    outSideFromCanvas: true,
    tabPosition: 'left',
    defaultVisible: true,
    placement: 'LB',
    offset: [0, 0],
    height: '400px',
    width: '400px'
  }
}, {
  id: 'StructAnalysis',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.PathStructureAnalysis',
    dm: '路径结构分析'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: $i18n.get({
        id: 'basic.src.templates.Query.PathStructureAnalysis',
        dm: '路径结构分析'
      }),
      isShowIcon: true,
      icon: 'icon-layout-tree',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'basic.src.templates.Query.AutomaticallyParseAllPathsOf',
        dm: '自动解析画布所有路径，做聚合分析'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '400px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'InfoDetection',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.InformationDetection',
    dm: '信息检测'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'basic.src.templates.Query.InformationDetection',
        dm: '信息检测'
      }),
      isShowIcon: true,
      icon: 'icon-infomation',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'basic.src.templates.Query.DetectIsolatedPointsRingsEtc',
        dm: '检测画布中孤立点、环等'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '400px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'NodeImportance',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.NodeImportance',
    dm: '节点重要性'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'basic.src.templates.Query.NodeImportance',
        dm: '节点重要性'
      }),
      isShowIcon: true,
      icon: 'icon-rules',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '350px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'CommunityDetection',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.CommunityDiscovery',
    dm: '社区发现'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'basic.src.templates.Query.CommunityDiscovery',
        dm: '社区发现'
      }),
      isShowIcon: true,
      icon: 'icon-associate',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '350px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'PatternMatch',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.PatternMatching',
    dm: '模式匹配'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'basic.src.templates.Query.PatternMatching',
        dm: '模式匹配'
      }),
      isShowIcon: true,
      icon: 'icon-query-path',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '350px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'CypherQuery',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'basic.src.templates.Query.CypherStatementQuery',
    dm: 'Cypher 语句查询'
  }),
  props: {
    serviceId: 'TuGraph/CypherQuery',
    isShowPublishButton: false,
    saveCypherTemplateServceId: 'GI/PublishTemplate',
    initialValue: 'MATCH n RETURN LIMIT 100',
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: $i18n.get({
        id: 'basic.src.templates.Query.CypherStatementQuery',
        dm: 'Cypher 语句查询'
      }),
      isShowIcon: true,
      icon: 'icon-query',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '60px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '350px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}];
var nodes = template.nodes,
  edges = template.edges,
  layout = template.layout,
  components = template.components;
export var config = {
  nodes: nodes,
  edges: edges,
  layout: layout,
  components: [].concat(_toConsumableArray(components), addComponents, [pageLayout])
};
export var activeAssetsKeys = {
  elements: [].concat(_toConsumableArray(config.nodes.map(function (n) {
    return n.id;
  })), _toConsumableArray(config.edges.map(function (e) {
    return e.id;
  }))),
  components: _toConsumableArray(components.map(function (c) {
    return c.id;
  })),
  layouts: ['Force2', 'Concentric', 'Dagre', 'FundForce', 'GraphinForce']
};
export default Object.assign({
  name: $i18n.get({
    id: 'basic.src.templates.Query.QueryTemplate',
    dm: '查询模版'
  }),
  id: 'TP_QUERY',
  image: "/image/tp_query.png",
  desc: $i18n.get({
    id: 'basic.src.templates.Query.TheTemplateWithTheQuery',
    dm: '以查询语句为主体的模版，包含 {activeAssetsKeysComponentsLength} 个分析资产，页面布局呈上下分布，最上方集成「Gremlin」或者「Cypher」查询语句，常用于数据库查询场景。'
  }, {
    activeAssetsKeysComponentsLength: activeAssetsKeys.components.length
  }),
  activeAssetsKeys: activeAssetsKeys
}, config);