export var Save = {
  name: '保存分享',
  req: "\n  export interface SaveReqParams {\n    /** \u4FDD\u5B58\u7684\u540D\u79F0 */\n    name: string;\n    /** \u4FDD\u5B58\u7684\u63CF\u8FF0 */\n    description: string;\n    /** \u4FDD\u5B58\u7684\u6570\u636E\uFF0C\u5E26\u5E03\u5C40\u4FE1\u606F **/\n    data: GraphinData;\n    /** GISDK\u7684\u914D\u7F6E\uFF0C\u53EF\u4EE5\u8FD8\u539F\u753B\u5E03\u72B6\u6001 */\n    config: GIConfig;\n    /** \u56FE\u7684 Schema \u7ED3\u6784 */\n    schemaData: GraphSchemaData;\n    /** \u753B\u5E03\u7684\u622A\u56FE */\n    cover: string;\n    /** \u4FDD\u5B58\u7684\u65F6\u95F4 */\n    gmtCreate: Date;\n  }\n  ",
  res: "\n  export interface SaveResParams {\n    /** \u4FDD\u5B58\u662F\u5426\u6210\u529F */\n    success: boolean;\n    /** \u4FDD\u5B58\u8DF3\u8F6C\u7684URL\u5730\u5740 **/\n    data: string;\n    /** \u4FDD\u5B58\u540E\uFF0C\u751F\u6210\u7684UUID */\n    shareId: string;\n  }\n  ",
  service: function service(params, localData) {
    var uuid = "rp_".concat(Math.random().toString(36).substr(2));
    var href = window.location.origin + '/#/share/' + uuid;
    //  window.localforage 是 G6VP 平台提供的全局变量，详情参考：https://github.com/localForage/localForage
    //@ts-ignore
    var _window = window,
      GI_REPORT_DB = _window.GI_REPORT_DB;
    GI_REPORT_DB.setItem(uuid, {
      id: uuid,
      type: 'save',
      params: JSON.stringify(params)
    });
    return new Promise(function (resolve) {
      return resolve({
        success: true,
        data: href,
        shareId: uuid
      });
    });
  }
};