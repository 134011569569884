import { utils } from '@antv/gi-sdk';
export var getToolbarStyleByConfig = function getToolbarStyleByConfig(config) {
  //@ts-ignore
  var toolbarCfg = config.components.find(function (c) {
    return c.id === 'Toolbar';
  }) || {
    id: 'Toolbar',
    props: {
      direction: 'horizontal',
      placement: 'LT',
      offset: [10, 10]
    }
  };
  var _toolbarCfg$props = toolbarCfg.props,
    direction = _toolbarCfg$props.direction,
    placement = _toolbarCfg$props.placement,
    offset = _toolbarCfg$props.offset;
  var positionStyles = utils.getPositionStyles(placement, offset);
  return {
    direction: direction,
    positionStyles: positionStyles
  };
};