import * as CypherQuery from './CypherQuery';
import * as Initializer from './Initializer';
import * as NeighborsQuery from './NeighborsQuery';
// import ServerComponent from './ServerComponent';
import Engine, { ENGINE_ID } from './Engine';
import $i18n from '../i18n';
export default {
  id: ENGINE_ID,
  type: 'database',
  name: 'TuGraph',
  desc: $i18n.get({
    id: 'tugraph.src.services.TugraphEngineService',
    dm: 'TuGraph 引擎服务'
  }),
  cover: 'https://gw.alipayobjects.com/mdn/rms_3ff49c/afts/img/A*xqsZTKLVHPsAAAAAAAAAAAAAARQnAQ',
  component: Engine,
  services: Object.assign(Object.assign(Object.assign({}, Initializer), CypherQuery), NeighborsQuery)
};