/**
 *
 * @param graphData 画布中的图数据
 * @param ids 需要b被过滤的节点 id
 * @returns
 */
export var filterGraphDataByNodes = function filterGraphDataByNodes(graphData, ids) {
  var edges = graphData.edges,
    nodes = graphData.nodes;
  //const ids = targetNodes.map(node => node.getModel().id);
  var newEdges = edges.filter(function (edge) {
    var source = edge.source,
      target = edge.target;
    if (ids.indexOf(source) !== -1 || ids.indexOf(target) !== -1) {
      return false;
    }
    return true;
  });
  var newNodes = nodes.filter(function (node) {
    return ids.indexOf(node.id) === -1;
  });
  return {
    nodes: newNodes,
    edges: newEdges
  };
};
/**
 * 获取边上的另一节点
 */
export var getEdgeOtherNode = function getEdgeOtherNode(edge, node) {
  if (edge.getSource().getModel().id === edge.getTarget().getModel().id) return;
  var edgeModel = edge.getModel();
  return edgeModel.source === node.getModel().id ? edge.getTarget() : edge.getSource();
};
/**
 * 获取某一个节点的直接关联节点
 */
export var getUniRelativeNodes = function getUniRelativeNodes(node) {
  var relativeEdges = node.getEdges();
  var relativeNodes = new Set();
  relativeEdges.forEach(function (edge) {
    var relativeNode = getEdgeOtherNode(edge, node);
    if (relativeNode) {
      relativeNodes.add(relativeNode);
    }
  });
  return Array.from(relativeNodes);
};
/**
 * 获得可以收起的节点
 */
export var getLeafNodes = function getLeafNodes(node) {
  var relativeEdges = node.getEdges();
  var relativeNodes = new Set();
  relativeEdges.forEach(function (edge) {
    var relativeNode = getEdgeOtherNode(edge, node);
    if (relativeNode) {
      var relativeNodeChildren = getUniRelativeNodes(relativeNode);
      if (!relativeNodeChildren.find(function (n) {
        return n.getModel().id !== node.getModel().id;
      })) {
        relativeNodes.add(relativeNode);
      }
    }
  });
  return Array.from(relativeNodes);
};