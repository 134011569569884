function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
export var getDefSideCoeFunction = function getDefSideCoeFunction(income, outcome, isLog, multiple) {
  var defSideCoe = function defSideCoe(node) {
    var value = 0;
    try {
      if (!node) {
        return 0;
      }
      var data = node.data.data;
      value = data[income] - data[outcome];
      if (isLog) {
        value = value / Math.abs(value) * Math.log(Math.abs(value));
      }
      value = value * parseFloat(multiple);
      return value;
    } catch (e) {
      console.log(e);
    }
    return value;
  };
  return defSideCoe;
};
export var getDefSideCoeFromEdgeFunction = function getDefSideCoeFromEdgeFunction(amountFromEdge, isLog, multiple) {
  var fieldMap = {};
  amountFromEdge.forEach(function (weightField) {
    var _weightField$split = weightField.split('^^'),
      _weightField$split2 = _slicedToArray(_weightField$split, 2),
      edgeType = _weightField$split2[0],
      field = _weightField$split2[1];
    fieldMap[edgeType] = field;
  });
  return function (node, edges) {
    if (!node || !(edges === null || edges === void 0 ? void 0 : edges.length)) return 0;
    var outTotal = 0;
    var inTotal = 0;
    edges.forEach(function (edge) {
      if (edge.source === node.id) {
        var fieldName = fieldMap[edge.edgeType];
        outTotal += Number(edge.data[fieldName]) || 0;
      }
      if (edge.target === node.id) {
        var _fieldName = fieldMap[edge.edgeType];
        inTotal += Number(edge.data[_fieldName]) || 0;
      }
    });
    var val = inTotal - outTotal;
    if (!val) return 0;
    if (isLog) {
      var sign = val >= 0 ? 1 : -1;
      val = sign * Math.log(Math.abs(val));
    }
    return val * parseFloat(multiple);
  };
};