import $i18n from '../../i18n';
var info = {
  id: 'RichContainer',
  name: $i18n.get({
    id: 'basic.components.RichContainer.info.MidEndLayout',
    dm: '中台布局'
  }),
  desc: $i18n.get({
    id: 'basic.components.RichContainer.info.MidEndLayout',
    dm: '中台布局'
  }),
  cover: 'http://xxx.jpg',
  category: 'system-interaction',
  icon: 'icon-home',
  type: 'GICC_LAYOUT'
};
export default info;