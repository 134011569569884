import $i18n from '../../i18n';
var info = {
  id: 'JSONMode',
  name: $i18n.get({
    id: 'advance.components.JSONMode.info.CodeMode',
    dm: '代码模式'
  }),
  desc: $i18n.get({
    id: 'advance.components.JSONMode.info.ShowDataInCode',
    dm: '将数据以代码形式展示'
  }),
  icon: 'icon-table',
  cover: 'http://xxxx.jpg',
  category: 'data-query',
  type: 'GIAC_CONTENT',
  docs: 'https://www.yuque.com/antv/gi/phx0p1kkgm2sei6o'
};
export default info;