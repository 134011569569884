function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { extra } from '@antv/gi-sdk';
import info from './info';
import $i18n from '../../i18n';
var deepClone = extra.deepClone,
  GIAC_CONTENT_METAS = extra.GIAC_CONTENT_METAS;
var metas = deepClone(GIAC_CONTENT_METAS);
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.title.default = info.name;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.icon.default = info.icon;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.tooltip.default = info.desc;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.containerWidth.default = '400px';
var registerMeta = function registerMeta(_ref) {
  var schemaData = _ref.schemaData;
  var nodeProperties = schemaData.nodes.reduce(function (acc, cur) {
    return Object.assign(Object.assign({}, acc), cur.properties);
  }, {});
  var edgeProperties = schemaData.edges.reduce(function (acc, cur) {
    return Object.assign(Object.assign({}, acc), cur.properties);
  }, {});
  var nodeOptions = Object.entries(nodeProperties).map(function (e) {
    var _e = _slicedToArray(e, 2),
      key = _e[0],
      value = _e[1];
    return {
      label: key,
      value: key
    };
  });
  var edgeOptions = Object.entries(edgeProperties).map(function (e) {
    var _e2 = _slicedToArray(e, 2),
      key = _e2[0],
      value = _e2[1];
    return {
      label: key,
      value: key
    };
  });
  return Object.assign({
    title: {
      title: $i18n.get({
        id: 'basic.components.ChartAnalysis.registerMeta.ChartTitle',
        dm: '图表标题'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Input',
      type: 'string',
      default: $i18n.get({
        id: 'basic.components.ChartAnalysis.registerMeta.UnnamedChart',
        dm: '未命名图表'
      })
    },
    chartType: {
      title: $i18n.get({
        id: 'basic.components.ChartAnalysis.registerMeta.ChartType',
        dm: '图表类型'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [{
        value: 'columnChart',
        label: $i18n.get({
          id: 'basic.components.ChartAnalysis.registerMeta.Histogram',
          dm: '柱状图'
        })
      }, {
        value: 'lineChart',
        label: $i18n.get({
          id: 'basic.components.ChartAnalysis.registerMeta.LineChart',
          dm: '折线图'
        })
      }],
      default: 'columnChart'
    },
    brushMode: {
      title: $i18n.get({
        id: 'basic.components.ChartAnalysis.registerMeta.InteractionMode',
        dm: '交互模式'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [{
        value: 'highlight',
        label: $i18n.get({
          id: 'basic.components.ChartAnalysis.registerMeta.Highlight',
          dm: '高亮'
        })
      }, {
        value: 'filter',
        label: $i18n.get({
          id: 'basic.components.ChartAnalysis.registerMeta.Filtering',
          dm: '过滤'
        })
      }],
      default: 'highlight'
    },
    height: {
      title: $i18n.get({
        id: 'basic.components.ChartAnalysis.registerMeta.ChartHeight',
        dm: '图表高度'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      type: 'number',
      default: undefined
    },
    dataType: {
      title: $i18n.get({
        id: 'basic.components.ChartAnalysis.registerMeta.DataType',
        dm: '数据类型'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [{
        value: 'nodes',
        label: $i18n.get({
          id: 'basic.components.ChartAnalysis.registerMeta.NodeData',
          dm: '节点数据'
        })
      }, {
        value: 'edges',
        label: $i18n.get({
          id: 'basic.components.ChartAnalysis.registerMeta.EdgeData',
          dm: '边数据'
        })
      }],
      default: 'edges',
      'x-reactions': [{
        target: 'xField_nodes',
        fulfill: {
          state: {
            visible: '{{$self.value==="nodes"?true:false }}'
          }
        }
      }, {
        target: 'yField_nodes',
        fulfill: {
          state: {
            visible: '{{$self.value==="nodes"?true:false }}'
          }
        }
      }, {
        target: 'xField_edges',
        fulfill: {
          state: {
            visible: '{{$self.value==="edges"?true:false }}'
          }
        }
      }, {
        target: 'yField_edges',
        fulfill: {
          state: {
            visible: '{{$self.value==="edges"?true:false }}'
          }
        }
      }]
    },
    xField_nodes: {
      title: $i18n.get({
        id: 'basic.components.ChartAnalysis.registerMeta.XAxisField',
        dm: 'X轴字段'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: _toConsumableArray(nodeOptions)
    },
    yField_nodes: {
      title: $i18n.get({
        id: 'basic.components.ChartAnalysis.registerMeta.YAxisField',
        dm: 'Y轴字段'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: _toConsumableArray(nodeOptions)
    },
    xField_edges: {
      title: $i18n.get({
        id: 'basic.components.ChartAnalysis.registerMeta.XAxisField',
        dm: 'X轴字段'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: _toConsumableArray(edgeOptions)
    },
    yField_edges: {
      title: $i18n.get({
        id: 'basic.components.ChartAnalysis.registerMeta.YAxisField',
        dm: 'Y轴字段'
      }),
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: _toConsumableArray(edgeOptions)
    }
  }, metas);
};
export default registerMeta;