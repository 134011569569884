function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { uniqueElementsBy } from './common';
export var getServiceOptions = function getServiceOptions(services, serviceId) {
  return services.filter(function (c) {
    return c.id.includes(serviceId);
  }).map(function (c) {
    return {
      value: c.id,
      label: c.id
    };
  });
};
export var getServiceOptionsByEngineId = function getServiceOptionsByEngineId(services, serviceId, engineId) {
  var options = services.filter(function (c) {
    return c.id.includes(serviceId);
  }).map(function (c) {
    return {
      value: c.id,
      label: c.id
    };
  });
  var defaultValue = options.find(function (c) {
    return c.value.startsWith(engineId);
  }) || options[0];
  return {
    options: options,
    defaultValue: (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.value) || ''
  };
};
export var getCombineServer = function getCombineServer(servers) {
  var serverMap = [];
  servers.forEach(function (server) {
    var id = server.id,
      services = server.services;
    var matchServer = serverMap[id];
    if (matchServer) {
      serverMap[id] = Object.assign(Object.assign(Object.assign({}, matchServer), server), {
        services: Object.assign(Object.assign({}, matchServer.services), services)
      });
    } else {
      serverMap[id] = server;
    }
  });
  return Object.values(serverMap);
};
/**
 *
 * @param servers 资产包中的引擎
 * @returns
 */
export var getCombineServices = function getCombineServices(servers) {
  if (!servers) {
    return [];
  }
  var sers = servers.reverse().reduce(function (acc, curr) {
    if (!curr.services) {
      return _toConsumableArray(acc);
    }
    var id = curr.id,
      services = curr.services;
    var sers = Object.keys(services).map(function (key) {
      var service = services[key];
      return Object.assign(Object.assign({}, service), {
        id: "".concat(id, "/").concat(key)
      });
    });
    return [].concat(_toConsumableArray(acc), _toConsumableArray(sers));
  }, []);
  return uniqueElementsBy(sers, function (a, b) {
    return a.id === b.id;
  });
};