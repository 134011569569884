import { extra, utils } from '@antv/gi-sdk';
import info from './info';
import $i18n from '../../i18n';
var GIAC_CONTENT_METAS = extra.GIAC_CONTENT_METAS,
  deepClone = extra.deepClone;
var metas = deepClone(GIAC_CONTENT_METAS);
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.icon.default = 'icon-bold';
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.title.default = $i18n.get({
  id: 'advance.components.StyleSetting.registerMeta.StyleSettings',
  dm: '样式设置'
});
var registerMeta = function registerMeta(context) {
  var services = context.services,
    engineId = context.engineId;
  var _utils$getServiceOpti = utils.getServiceOptionsByEngineId(services, info.services[0], engineId),
    options = _utils$getServiceOpti.options,
    defaultValue = _utils$getServiceOpti.defaultValue;
  return Object.assign({
    serviceId: {
      title: $i18n.get({
        id: 'advance.components.StyleSetting.registerMeta.DataService',
        dm: '数据服务'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: options
      },
      default: defaultValue
    }
  }, metas);
};
/** 默认的配置值 */
export default registerMeta;