import { extra } from '@antv/gi-sdk';
import $i18n from '../../i18n';
var deepClone = extra.deepClone,
  GI_CONTAINER_METAS = extra.GI_CONTAINER_METAS;
var metas = deepClone(GI_CONTAINER_METAS);
var width = metas.width,
  offset = metas.offset,
  placement = metas.placement;
width.default = '350px';
offset.default = [0, 2];
var registerMeta = function registerMeta(context) {
  var _context$GIAC_CONTENT = context.GIAC_CONTENT_ITEMS,
    GIAC_CONTENT_ITEMS = _context$GIAC_CONTENT === void 0 ? [] : _context$GIAC_CONTENT;
  var schema = {
    GI_CONTAINER: {
      title: $i18n.get({
        id: 'basic.components.SideSelectTabs.registerMeta.IntegratedComponents',
        dm: '集成组件'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        mode: 'multiple'
      },
      enum: GIAC_CONTENT_ITEMS,
      default: []
    },
    width: width,
    offset: offset,
    placement: {
      title: $i18n.get({
        id: 'basic.components.SideSelectTabs.registerMeta.PlacementOrientation',
        dm: '放置方位'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: [{
          value: 'LB',
          label: $i18n.get({
            id: 'basic.components.SideSelectTabs.registerMeta.LowerLeftLeft',
            dm: '左下 / left'
          })
        }]
      },
      default: 'LB'
    }
  };
  return schema;
};
export default registerMeta;