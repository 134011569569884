import CommunityDetection from './CommunityDetection';
import InfoDetection from './InfoDetection';
import NodeImportance from './NodeImportance';
import NodesClustering from './NodesClustering';
import NodesSimilarity from './NodesSimilarity';
import PatternMatch from './PatternMatch';
var components = {
  NodesClustering: NodesClustering,
  NodesSimilarity: NodesSimilarity,
  CommunityDetection: CommunityDetection,
  NodeImportance: NodeImportance,
  PatternMatch: PatternMatch,
  InfoDetection: InfoDetection
};
export { components };