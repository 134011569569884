export var scaleNodes = function scaleNodes(graphData) {
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 600;
  var _graphData$nodes = graphData.nodes,
    nodes = _graphData$nodes === void 0 ? [] : _graphData$nodes,
    _graphData$edges = graphData.edges,
    edges = _graphData$edges === void 0 ? [] : _graphData$edges;
  if (!(nodes === null || nodes === void 0 ? void 0 : nodes.length)) {
    return;
  }
  var minX = Infinity;
  var maxX = -Infinity;
  var minY = Infinity;
  var maxY = -Infinity;
  nodes.forEach(function (node) {
    if (node.x === undefined || node.y === undefined) {
      return;
    }
    if (node.x < minX) {
      minX = node.x;
    }
    if (node.x > maxX) {
      maxX = node.x;
    }
    if (node.y < minY) {
      minY = node.y;
    }
    if (node.y > maxY) {
      maxY = node.y;
    }
  });
  var wRatio = (maxX - minX) / value;
  var hRatio = (maxY - minY) / value;
  var ratio = Math.max(wRatio, hRatio);
  if (ratio < 1) {
    return {
      nodes: nodes,
      edges: edges
    };
  }
  return {
    nodes: nodes.map(function (node) {
      return Object.assign(Object.assign({}, node), {
        x: node.x ? node.x / ratio : undefined,
        y: node.y ? node.y / ratio : undefined
      });
    }),
    edges: edges
  };
};
export var dataURLToImage = function dataURLToImage(dataURL, renderer, link, fileName) {
  if (!dataURL || dataURL === 'data:') {
    console.error('Download image failed. The graph is too large or there is invalid attribute values in graph items');
    return;
  }
  if (typeof window !== 'undefined') {
    if (window.Blob && window.URL && renderer !== 'svg') {
      var arr = dataURL.split(',');
      var mime = '';
      if (arr && arr.length > 0) {
        var match = arr[0].match(/:(.*?);/);
        // eslint-disable-next-line prefer-destructuring
        if (match && match.length >= 2) mime = match[1];
      }
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var blobObj = new Blob([u8arr], {
        type: mime
      });
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blobObj, fileName);
      } else {
        link.addEventListener('click', function () {
          link.download = fileName;
          link.href = window.URL.createObjectURL(blobObj);
        });
      }
    } else {
      link.addEventListener('click', function () {
        link.download = fileName;
        link.href = dataURL;
      });
    }
  }
  var e = document.createEvent('MouseEvents');
  e.initEvent('click', false, false);
  link.dispatchEvent(e);
};
export default {
  scaleNodes: scaleNodes,
  dataURLToImage: dataURLToImage
};