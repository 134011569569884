export var getDefSpringLenFunction = function getDefSpringLenFunction(springConfig) {
  var _springConfig$default = springConfig.defaultSpring,
    defaultSpring = _springConfig$default === void 0 ? 100 : _springConfig$default,
    _springConfig$maxLimi = springConfig.maxLimitLength,
    maxLimitLength = _springConfig$maxLimi === void 0 ? 500 : _springConfig$maxLimi,
    _springConfig$minLimi = springConfig.minLimitDegree,
    minLimitDegree = _springConfig$minLimi === void 0 ? 5 : _springConfig$minLimi;
  var defSpringLen = function defSpringLen(_edge, source, target) {
    /** 默认返回的是 200 的弹簧长度 */
    /** 如果你要想要产生聚类的效果，可以考虑
    根据边两边节点的度数来动态设置边的初始化长度：度数越小，则边越短 */
    var Sdegree = source.data.layout.degree;
    var Tdegree = target.data.layout.degree;
    var MinDegree = Math.min(Sdegree, Tdegree);
    var SpringLength = defaultSpring;
    if (MinDegree < minLimitDegree) {
      SpringLength = defaultSpring * MinDegree;
    } else {
      SpringLength = maxLimitLength;
    }
    return SpringLength;
  };
  return defSpringLen;
};