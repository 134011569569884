/**
 * 获取服务引擎的上下文
 * @returns
 */
export var getServerEngineContext = function getServerEngineContext() {
  var defaltContext = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  try {
    var context = JSON.parse(localStorage.getItem('SERVER_ENGINE_CONTEXT') || '{}');
    return Object.assign(Object.assign({}, defaltContext), context);
  } catch (error) {
    console.error(error);
    return {};
  }
};
/**
 * 设置服务引擎的上下文
 * @returns
 */
export var setServerEngineContext = function setServerEngineContext(context) {
  try {
    var preContext = JSON.parse(localStorage.getItem('SERVER_ENGINE_CONTEXT') || '{}');
    localStorage.setItem('SERVER_ENGINE_CONTEXT', JSON.stringify(Object.assign(Object.assign({}, preContext), context)));
  } catch (error) {
    console.error(error);
  }
};