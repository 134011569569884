import $i18n from '../../i18n';
var info = {
  id: 'Export',
  name: $i18n.get({
    id: 'basic.components.Export.info.Export',
    dm: '导出'
  }),
  desc: $i18n.get({
    id: 'basic.components.Export.info.ExportCsvPngJsonData',
    dm: '导出CSV,PNG,JSON数据'
  }),
  icon: 'icon-export',
  cover: 'http://xxxx.jpg',
  category: 'workbook',
  type: 'GIAC',
  docs: 'https://www.yuque.com/antv/gi/vgsvy7op54os371m'
};
export default info;