function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getKeysByData } from './utils';
/**
 *
 * @param assets 服务端拿到的资产：Elements
 * @param data 图数据
 * @returns
 */
var getElementsByAssets = function getElementsByAssets(assets, data) {
  var nodeElements = {};
  var edgeElements = {};
  Object.keys(assets).forEach(function (key) {
    var element = assets[key];
    //@ts-ignore
    var info = element.info,
      registerMeta = element.registerMeta,
      registerShape = element.registerShape,
      registerTransform = element.registerTransform,
      defaultProps = element.defaultProps;
    var id = info.id,
      name = info.name,
      category = info.category,
      type = info.type;
    var keys = getKeysByData(data, category);
    var configObj = registerMeta({
      data: data,
      keys: keys
    });
    /** 默认的配置值 */
    // const defaultProps = extractDefault({ config: configObj, value: {} });
    var item = {
      id: id,
      props: defaultProps,
      name: name,
      info: info,
      // meta: { configObj },
      meta: configObj
      // registerShape,
      // registerTransform,
    };

    if (category === 'node' || type === 'NODE') {
      nodeElements = Object.assign(Object.assign({}, nodeElements), _defineProperty({}, id, item));
    }
    if (category === 'edge' || type === 'EDGE') {
      edgeElements = Object.assign(Object.assign({}, edgeElements), _defineProperty({}, id, item));
    }
  });
  return {
    nodes: nodeElements,
    edges: edgeElements
  };
};
export default getElementsByAssets;