import { extra } from '@antv/gi-sdk';
import info from './info';
import { NodeSelectionMode, getNodeFormatOption } from '@antv/gi-common-components';
import $i18n from '../i18n';
var deepClone = extra.deepClone,
  GIAC_CONTENT_METAS = extra.GIAC_CONTENT_METAS;
var metas = deepClone(GIAC_CONTENT_METAS);
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.title.default = info.name;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.icon.default = info.icon;
var nodeSelectionOption = [{
  value: NodeSelectionMode.List,
  label: $i18n.get({
    id: 'gi-assets-algorithm.src.NodesSimilarity.registerMeta.ListAcquisition',
    dm: '列表获取'
  })
}, {
  value: NodeSelectionMode.Canvas,
  label: $i18n.get({
    id: 'gi-assets-algorithm.src.NodesSimilarity.registerMeta.CanvasPickup',
    dm: '画布拾取'
  })
}];
var nodeSelectionDefaultValue = nodeSelectionOption.map(function (item) {
  return item.value;
});
var registerMeta = function registerMeta(_ref) {
  var schemaData = _ref.schemaData;
  var nodeProperties = schemaData.nodes.reduce(function (acc, cur) {
    return Object.assign(Object.assign({}, acc), cur.properties);
  }, {});
  var options = Object.keys(nodeProperties).filter(function (key) {
    return nodeProperties[key] === 'string';
  }).map(function (e) {
    return {
      value: e,
      label: e
    };
  }).sort(function (a, b) {
    return a.value.localeCompare(b.value);
  });
  return Object.assign(Object.assign({
    nodeSelectionMode: {
      title: $i18n.get({
        id: 'gi-assets-algorithm.src.NodesSimilarity.registerMeta.ObtainNodeMode',
        dm: '获取节点模式'
      }),
      type: 'array',
      enum: nodeSelectionOption,
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        mode: 'multiple'
      },
      default: nodeSelectionDefaultValue
    },
    nodeLabel: {
      title: $i18n.get({
        id: 'gi-assets-algorithm.src.NodesSimilarity.registerMeta.TagMapping',
        dm: '标签映射'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: options,
      default: 'id',
      'x-component-props': {
        showSearch: true
      }
    }
  }, getNodeFormatOption(options)), metas);
};
export default registerMeta;