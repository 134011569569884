import { extra, utils } from '@antv/gi-sdk';
import info from './info';
import $i18n from '../../i18n';
var GIAC_CONTENT_METAS = extra.GIAC_CONTENT_METAS,
  deepClone = extra.deepClone;
var metas = deepClone(GIAC_CONTENT_METAS);
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.icon.default = info.icon;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.title.default = info.name;
var registerMeta = function registerMeta(context) {
  var _context$services = context.services,
    services = _context$services === void 0 ? [] : _context$services,
    engineId = context.engineId;
  var _utils$getServiceOpti = utils.getServiceOptionsByEngineId(services, info.services[0], engineId),
    options = _utils$getServiceOpti.options,
    defaultValue = _utils$getServiceOpti.defaultValue;
  var _utils$getServiceOpti2 = utils.getServiceOptionsByEngineId(services, info.services[1], engineId),
    publishOptions = _utils$getServiceOpti2.options,
    publishDefaultValue = _utils$getServiceOpti2.defaultValue;
  return Object.assign({
    /** 分类信息 */
    serviceId: {
      title: $i18n.get({
        id: 'advance.components.CypherQuery.registerMeta.DataService',
        dm: '数据服务'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: options
      },
      default: defaultValue
    },
    limit: {
      title: $i18n.get({
        id: 'advance.components.CypherQuery.registerMeta.QueryLimits',
        dm: '查询限制'
      }),
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      default: 100
    },
    isShowPublishButton: {
      title: $i18n.get({
        id: 'advance.components.CypherQuery.registerMeta.PublishAsATemplate',
        dm: '发布成模板'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      'x-reactions': [{
        target: 'saveCypherTemplateServceId',
        fulfill: {
          state: {
            visible: '{{$self.value}}'
          }
        }
      }],
      default: false
    },
    saveCypherTemplateServceId: {
      title: $i18n.get({
        id: 'advance.components.CypherQuery.registerMeta.PublishTemplateService',
        dm: '发布模板服务'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: publishOptions
      },
      default: publishDefaultValue
    },
    initialValue: {
      title: $i18n.get({
        id: 'advance.components.CypherQuery.registerMeta.InitialStatement',
        dm: '初始语句'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input.TextArea',
      default: 'MATCH n RETURN LIMIT 100'
    }
  }, metas);
};
export default registerMeta;