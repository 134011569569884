function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var process = function process(item) {};
var getData = function getData(type, _ref) {
  var largeGraphData = _ref.largeGraphData,
    selectItems = _ref.selectItems,
    graphData = _ref.graphData;
  // 计算数据
  var items = graphData[type];
  if (selectItems[type].length !== 0) {
    items = selectItems[type];
  }
  if (largeGraphData) {
    items = largeGraphData[type];
  }
  // 节点和边的数据处理过程不太一样，边要考虑聚合边
  if (type === 'nodes') {
    return items.map(function (item) {
      return Object.assign(Object.assign({}, item.data), {
        id: item.id
      });
    });
  }
  if (type === 'edges') {
    //@ts-ignore
    return items.reduce(function (acc, curr) {
      var aggregate = curr.aggregate;
      // 表格模式下，需要将聚合边拆开展示
      if (aggregate && aggregate.length > 0) {
        var aggs = aggregate.map(function (c) {
          return Object.assign(Object.assign({}, c.data), {
            id: c.id
          });
        });
        return [].concat(_toConsumableArray(acc), _toConsumableArray(aggs));
      }
      return [].concat(_toConsumableArray(acc), [Object.assign(Object.assign({}, curr.data), {
        id: curr.id
      })]);
    }, []);
  }
};
export default getData;