export var highlightSubGraph = function highlightSubGraph(graph, data) {
  var source = graph.save();
  var nodeIds = data.nodes.map(function (node) {
    return node.id;
  });
  var edgeIds = [];
  /** 需要考虑聚合边的情况，需要构造全量的边 */
  data.edges.forEach(function (edge) {
    var aggregate = edge.aggregate;
    if (aggregate) {
      aggregate.forEach(function (item) {
        edgeIds.push(item.id);
      });
    } else {
      edgeIds.push(edge.id);
    }
  });
  var sourceNodesCount = source.nodes.length;
  var sourceEdgesCount = edgeIds.length; //考虑聚合边
  var nodesCount = data.nodes.length;
  var edgesCount = data.edges.length;
  var isEmpty = nodesCount === 0 && edgesCount === 0;
  var isFull = nodesCount === sourceNodesCount && edgesCount === sourceEdgesCount;
  // 如果是空或者全部图数据，则恢复到画布原始状态，取消高亮
  if (isEmpty || isFull) {
    source.nodes.forEach(function (node) {
      graph.clearItemStates(node.id);
    });
    source.edges.forEach(function (edge) {
      graph.clearItemStates(edge.id);
    });
    return {
      isEmpty: isEmpty,
      isFull: isFull
    };
  }
  source.nodes.forEach(function (node) {
    var hasMatch = nodeIds.includes(node.id);
    if (hasMatch) {
      graph.setItemState(node.id, 'disabled', false);
      graph.setItemState(node.id, 'selected', true);
    } else {
      graph.setItemState(node.id, 'selected', false);
      graph.setItemState(node.id, 'disabled', true);
    }
  });
  source.edges.forEach(function (edge) {
    var aggregate = edge.aggregate,
      id = edge.id;
    var hasMatch = false;
    /** 考虑聚合边的情况，aggregate 数据中的 edgeId 匹配上一个就可以高亮整个聚合边 */
    if (aggregate) {
      hasMatch = aggregate.map(function (e) {
        return e.id;
      }).some(function (itemId) {
        return edgeIds.includes(itemId);
      });
    } else {
      hasMatch = edgeIds.includes(id);
    }
    if (hasMatch) {
      graph.setItemState(edge.id, 'disabled', false);
      graph.setItemState(edge.id, 'selected', true);
    } else {
      graph.setItemState(edge.id, 'selected', false);
      graph.setItemState(edge.id, 'disabled', true);
    }
  });
  return {
    isEmpty: isEmpty,
    isFull: isFull
  };
};
/**
 *
 * @param graph Graph
 * @param edges string[]
 * @returns
 */
export var highlightEdgeIds = function highlightEdgeIds(graph, edgeIds) {
  var source = graph.save();
  var isEmpty = edgeIds.length === 0;
  // 如果是空或者全部图数据，则恢复到画布原始状态，取消高亮
  if (isEmpty) {
    source.edges.forEach(function (edge) {
      graph.clearItemStates(edge.id);
    });
    return {
      isEmpty: isEmpty
    };
  }
  source.edges.forEach(function (edge) {
    var aggregate = edge.aggregate,
      id = edge.id,
      source = edge.source,
      target = edge.target;
    var hasMatch = false;
    /** 考虑聚合边的情况，aggregate 数据中的 edgeId 匹配上一个就可以高亮整个聚合边 */
    if (aggregate) {
      hasMatch = aggregate.map(function (e) {
        return e.id;
      }).some(function (itemId) {
        return edgeIds.includes(itemId);
      });
    } else {
      hasMatch = edgeIds.includes(id);
    }
    if (hasMatch) {
      graph.setItemState(id, 'disabled', false);
      graph.setItemState(id, 'selected', true);
      graph.setItemState(source, 'selected', true);
      graph.setItemState(target, 'selected', true);
      graph.updateItem(id, {
        style: {
          animate: {
            visible: true,
            type: 'circle-running',
            color: 'rgba(236,65,198,1)',
            repeat: true,
            duration: 1000
          }
        }
      });
    } else {
      graph.setItemState(id, 'selected', false);
      graph.setItemState(id, 'disabled', true);
      graph.setItemState(source, 'disable', true);
      graph.setItemState(target, 'disable', true);
      graph.setItemState(source, 'selected', false);
      graph.setItemState(target, 'selected', false);
      graph.updateItem(id, {
        style: {
          animate: {
            visible: false,
            type: 'circle-running',
            color: 'rgba(236,65,198,1)',
            repeat: true,
            duration: 1000
          }
        }
      });
    }
  });
  return {
    isEmpty: isEmpty
  };
};