/** export  */
import pkg from '../package.json';
import { deepClone, GI_CONTAINER_METAS, GIAC_CONTENT_METAS, GIAC_CONTENT_PROPS, GIAC_METAS, GIAC_PROPS } from './components/const';
import GIAComponent from './components/GIAC';
import template from './constants/template';
import { useContext } from './context';
import GISDK from './GISDK';
import * as utils from './process';
var version = pkg.version;
console.log("%c \uD83C\uDF89 GI_SDK_VERSION:".concat(version), 'color:#3e5dff');
var extra = {
  /** 原子组件 META */
  GIAC_CONTENT_METAS: GIAC_CONTENT_METAS,
  GIAC_CONTENT_PROPS: GIAC_CONTENT_PROPS,
  GIAC_METAS: GIAC_METAS,
  GIAC_PROPS: GIAC_PROPS,
  /** 容器组件 META */
  GI_CONTAINER_METAS: GI_CONTAINER_METAS,
  deepClone: deepClone,
  GIAComponent: GIAComponent
};
export { Icon, icons } from '@antv/gi-common-components';
export { default as CollapseCard } from './components/CollapseCard';
export { default as EngineBanner } from './components/EngineBanner';
export { default as EngineServer } from './components/EngineServer';
export { default as Studio } from './components/Studio';
export { Info } from './constants/info';
export { default as useContainer } from './hooks/useContainer';
export { Shortcuts, useShortcuts } from './utils';
import { createDownload } from './utils';
var common = {
  createDownload: createDownload
};
export { common };
// export { default as Icon } from './components/Icon';
/** export typing */
export { COLORS } from './process/schema';
export { changeLanguage, formatMessage, getCurrentLanguage, LANGUAGE_KEY_NAME } from './process/locale';
export { extra, template, useContext, utils, version };
export default GISDK;