function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { Utils } from '@antv/graphin';
import merge from 'deepmerge';
var defaultEdgeTheme = {
  primaryEdgeColor: '#ddd',
  edgeSize: 1,
  mode: 'light'
};
var defaultEdgeStyles = Utils.getEdgeStyleByTheme(defaultEdgeTheme);
var style = defaultEdgeStyles.style;
var keyshape = style.keyshape,
  label = style.label;
export var defaultConfig = {
  size: defaultEdgeTheme.edgeSize,
  color: defaultEdgeTheme.primaryEdgeColor,
  label: [],
  advanced: {
    keyshape: {
      customPoly: false,
      poly: 0,
      lineDash: [0, 0],
      // lineAppendWidth: keyshape.lineWidth,
      opacity: keyshape.strokeOpacity,
      hasArrow: true
    },
    label: {
      visible: true,
      fontSize: label.fontSize,
      offset: [0, 0],
      fill: label.fill,
      backgroundEnable: true,
      backgroundFill: '#fff',
      backgroundStroke: '#fff',
      backgroundOpaciy: 1,
      opacity: 1
    },
    animate: {
      visible: false,
      type: 'circle-running',
      dotColor: 'red',
      repeat: true,
      duration: 3000
    }
  },
  status: {
    minZoom: {
      label: {
        opacity: 0
      },
      'label-background': {
        opacity: 0
      }
    }
  }
};
/** 数据映射函数  需要根据配置自动生成*/
var transform = function transform(edges, config, reset) {
  try {
    var _merge = merge(defaultConfig, config.props),
      color_CFG = _merge.color,
      size_CFG = _merge.size,
      LABEL_KEYS = _merge.label,
      advanced = _merge.advanced,
      defaultStatus = _merge.status;
    var keyshape_CFG = advanced.keyshape;
    var transEdges = edges.map(function (edge, index) {
      var _a, _b;
      // properties
      var source = edge.source,
        target = edge.target;
      var id = edge.id || "".concat(source, "-").concat(target, "-").concat(index);
      var data = edge.data || edge.properties || edge;
      var isLoop = edge.source === edge.target; //edge.style && edge.style.keyshape && edge.style.keyshape.type === 'loop';
      var isPoly = edge.isMultiple;
      var endArrow = {};
      var customPoly = keyshape_CFG.customPoly,
        hasArrow = keyshape_CFG.hasArrow;
      if (!hasArrow) {
        //@ts-ignore
        endArrow = {
          endArrow: {
            path: ''
          }
        };
      }
      var shape = {};
      if (isLoop) {
        shape.type = 'loop';
        shape.loop = Object.assign({}, (_a = edge.style) === null || _a === void 0 ? void 0 : _a.keyshape.loop);
      }
      if (isPoly) {
        shape.type = 'poly';
        shape.poly = Object.assign({}, (_b = edge.style) === null || _b === void 0 ? void 0 : _b.keyshape.poly);
      }
      if (!isPoly && !isLoop) {
        //只有直线的时候才支持设置弧度，多边的默认是系统分配的弧度
        shape.type = 'poly';
        shape.poly = {
          distance: advanced.keyshape.poly
        };
      }
      if (customPoly) {
        //如果用户要强行自定义弧度，那就随他去吧
        shape.poly = {
          distance: advanced.keyshape.poly
        };
      }
      /** LABEL */
      // const LABEL_VALUE = LABEL_KEYS.map(l => data[l]).join('_');
      var LABEL_VALUE = LABEL_KEYS.map(function (d) {
        /**
         * 兼容性处理：原先的label 逻辑是 ${type}.${properpertiesKey}
         * 现在改为 ${type}^^${properpertiesKey}
         */
        var newLabelArray = d.split('^^');
        var oldLabelArray = d.split('.');
        var _newLabelArray = _slicedToArray(newLabelArray, 3),
          edgeType = _newLabelArray[0],
          propObjKey = _newLabelArray[1],
          propName = _newLabelArray[2];
        var isOld = newLabelArray.length === 1 && newLabelArray[0].split('.').length > 1;
        if (isOld) {
          edgeType = oldLabelArray[0];
          propObjKey = oldLabelArray[1];
          propName = oldLabelArray[2];
        }
        // const [edgeType, propObjKey, propName] = d.split('^^');
        // 只有当 nodeType 匹配时才取对应的属性值
        if (edge.edgeType || 'UNKNOW' === edgeType) {
          // propName 存在，则 propObjKey 值一定为 properties
          if (propName) {
            return data[propObjKey][propName];
          }
          /** 如果有汇总边，则强制使用汇总边的文本展示 */
          var aggregate = data.aggregate;
          if (aggregate) {
            var sum = aggregate.reduce(function (acc, curr) {
              var val = curr.data[propObjKey];
              if (typeof val === 'number') {
                acc = acc + val;
                return acc;
              } else {
                return '';
              }
            }, 0);
            if (sum === '') {
              return data['aggregateCount'];
            }
            return "(".concat(aggregate.length, " \u6761)\uFF1A").concat(sum.toFixed(2));
          }
          return data[propObjKey];
        }
        return data[edgeType];
      }).filter(function (d) {
        return d;
      }).join('\n');
      var label = {
        value: LABEL_VALUE,
        offset: advanced.label.offset,
        fontSize: advanced.label.fontSize,
        fill: advanced.label.fill,
        opacity: advanced.label.opacity
      };
      if (!advanced.label.visible) {
        label.value = '';
      }
      if (advanced.label.backgroundEnable) {
        label.background = {
          fill: advanced.label.backgroundFill,
          stroke: advanced.label.backgroundStroke,
          opacity: advanced.label.backgroundOpaciy
        };
      }
      var preStyle = edge && edge.style || {};
      if (reset) {
        preStyle = {};
      }
      var finalStyle = merge({
        keyshape: Object.assign(Object.assign(Object.assign({}, shape), {
          // ...edge.style?.keyshape,
          lineWidth: size_CFG,
          stroke: color_CFG,
          opacity: keyshape_CFG.opacity,
          lineDash: keyshape_CFG.lineDash,
          lineAppendWidth: 10
        }), endArrow),
        label: label,
        animate: {
          visible: advanced.animate.visible,
          type: advanced.animate.type,
          color: advanced.animate.dotColor,
          repeat: advanced.animate.repeat,
          duration: advanced.animate.duration
        },
        status: Object.assign({}, defaultStatus)
      }, preStyle);
      return Object.assign(Object.assign({}, edge), {
        source: source,
        target: target,
        id: id,
        data: data,
        type: 'graphin-line',
        edgeType: edge.edgeType || 'UNKOWN',
        style: finalStyle
      });
    });
    return transEdges;
  } catch (error) {
    console.error('parse transform error:', error);
    return edges;
  }
};
export default transform;