import $i18n from '../../i18n';
var info = {
  id: 'LassoSelect',
  name: $i18n.get({
    id: 'basic.components.LassoSelect.info.FreeSelection',
    dm: '自由圈选'
  }),
  desc: $i18n.get({
    id: 'basic.components.LassoSelect.info.YouCanUseTheCable',
    dm: '可通过拉索工具，自由圈选画布元素'
  }),
  icon: 'icon-lasso',
  cover: 'http://xxxx.jpg',
  category: 'canvas-interaction',
  type: 'GIAC',
  docs: 'https://www.yuque.com/antv/gi/uhzkzcuqcgzm7c56'
};
export default info;