import $i18n from '../../i18n';
var info = {
  id: 'ZoomOut',
  name: $i18n.get({
    id: 'basic.components.ZoomOut.info.ZoomOut',
    dm: '缩小'
  }),
  desc: $i18n.get({
    id: 'basic.components.ZoomOut.info.ClickToNarrowTheCanvas',
    dm: '点击可缩小画布'
  }),
  icon: 'icon-zoomout',
  cover: 'http://xxxx.jpg',
  category: 'canvas-interaction',
  type: 'GIAC',
  docs: 'https://www.yuque.com/antv/gi/mw2wxx9h6kz1lp0z'
};
export default info;