var DEFAULT_CONNECT_INFO = {
  username: '',
  password: '',
  serverUrl: ''
};
export var getConnectInfo = function getConnectInfo() {
  try {
    var Galaxybase_CONNECT_INFO_STRING = localStorage.getItem('Galaxybase_CONNECT_INFO') || '{}';
    var _JSON$parse = JSON.parse(Galaxybase_CONNECT_INFO_STRING),
      _JSON$parse$username = _JSON$parse.username,
      username = _JSON$parse$username === void 0 ? DEFAULT_CONNECT_INFO.username : _JSON$parse$username,
      _JSON$parse$password = _JSON$parse.password,
      password = _JSON$parse$password === void 0 ? DEFAULT_CONNECT_INFO.password : _JSON$parse$password,
      _JSON$parse$serverUrl = _JSON$parse.serverUrl,
      serverUrl = _JSON$parse$serverUrl === void 0 ? DEFAULT_CONNECT_INFO.serverUrl : _JSON$parse$serverUrl;
    return {
      username: username,
      password: password,
      serverUrl: serverUrl
    };
  } catch (error) {
    return DEFAULT_CONNECT_INFO;
  }
};
export var setConnectInfo = function setConnectInfo(params) {
  localStorage.setItem('Galaxybase_CONNECT_INFO', JSON.stringify(params));
};
export var formatterSchemaData = function formatterSchemaData(schemaData) {
  var nodes = [],
    edges = [];
  Object.keys(schemaData.vertexes).forEach(function (vertex) {
    var vertexData = schemaData.vertexes[vertex],
      properties = {};
    vertexData.property.forEach(function (pro) {
      properties[pro.name] = pro.type;
    });
    nodes.push({
      nodeType: vertexData.type,
      nodeTypeKeyFromProperties: '$_type',
      properties: properties
    });
  });
  Object.keys(schemaData.edges).forEach(function (edge) {
    var edgeData = schemaData.edges[edge],
      properties = {};
    edgeData.fromAndToTypes.forEach(function (type) {
      edgeData.property.forEach(function (pro) {
        properties[pro.name] = pro.type;
      });
      edges.push({
        edgeType: edgeData.type,
        edgeTypeKeyFromProperties: '$_type',
        sourceNodeType: type.fromType,
        targetNodeType: type.toType,
        properties: properties
      });
    });
  });
  return {
    nodes: nodes,
    edges: edges
  };
};
export var formatterCypherResult = function formatterCypherResult(results) {
  var nodes = [],
    edges = [];
  results[0].data.forEach(function (graph) {
    nodes = nodes.concat(graph.graph.nodes.map(function (item) {
      return {
        id: item.id + '',
        nodeType: item.labels[0],
        data: Object.assign({
          id: item.id + '',
          $_type: item.labels[0]
        }, item.properties),
        nodeTypeKeyFromProperties: '$_type'
      };
    }));
    edges = edges.concat(graph.graph.relationships.map(function (item) {
      return {
        id: item.id,
        source: item.startNode,
        target: item.endNode,
        edgeType: item.type,
        data: Object.assign({
          $_type: item.type
        }, item.properties),
        edgeTypeKeyFromProperties: '$_type'
      };
    }));
  });
  var resultEdges = [];
  edges.forEach(function (item) {
    if (!resultEdges.some(function (e) {
      return e.id == item.id;
    })) resultEdges.push(item);
  });
  return {
    nodes: nodes,
    edges: resultEdges
  };
};