import $i18n from '../../i18n';
var info = {
  id: 'Redo',
  name: $i18n.get({
    id: 'advance.components.Redo.info.Redo',
    dm: '重做'
  }),
  desc: $i18n.get({
    id: 'advance.components.Redo.info.RedoTheCanvasEntersThe',
    dm: '重做，画布进入下一步状态'
  }),
  cover: 'http://xxxx.jpg',
  category: 'canvas-interaction',
  type: 'GIAC',
  icon: 'icon-redo',
  docs: 'https://www.yuque.com/antv/gi/qe8vp7n9snmv54qn'
};
export default info;