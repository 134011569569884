import $i18n from '../../i18n';
var info = {
  id: 'Tooltip',
  name: $i18n.get({
    id: 'basic.components.Tooltip.info.NodePromptBox',
    dm: '节点提示框'
  }),
  desc: $i18n.get({
    id: 'basic.components.Tooltip.info.TheHoverNodeShowingIts',
    dm: 'Hover 节点，展示其详细信息'
  }),
  cover: 'http://xxxx.jpg',
  category: 'elements-interaction',
  icon: 'icon-tooltip',
  type: 'AUTO',
  docs: 'https://www.yuque.com/antv/gi/atogtq0417351bpq'
};
export default info;