import { extra } from '@antv/gi-sdk';
import $i18n from '../../i18n';
var deepClone = extra.deepClone,
  GI_CONTAINER_METAS = extra.GI_CONTAINER_METAS;
var metas = deepClone(GI_CONTAINER_METAS);
metas.placement.default = 'LB';
metas.height.default = '400px';
metas.width.default = '400px';
var registerMeta = function registerMeta(context) {
  var _context$GIAC_CONTENT = context.GIAC_CONTENT_ITEMS,
    GIAC_CONTENT_ITEMS = _context$GIAC_CONTENT === void 0 ? [] : _context$GIAC_CONTENT;
  var schema = Object.assign({
    GI_CONTAINER: {
      title: $i18n.get({
        id: 'basic.components.SideTabs.registerMeta.IntegratedComponents',
        dm: '集成组件'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        mode: 'multiple'
      },
      enum: GIAC_CONTENT_ITEMS,
      default: []
    },
    outSideFromCanvas: {
      title: $i18n.get({
        id: 'basic.components.SideTabs.registerMeta.IndependentDom',
        dm: '独立DOM'
      }),
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    },
    tabPosition: {
      title: $i18n.get({
        id: 'basic.components.SideTabs.registerMeta.NavigationLayout',
        dm: '导航布局'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [{
        label: 'left',
        value: 'left'
      }, {
        label: 'right',
        value: 'right'
      }, {
        label: 'top',
        value: 'top'
      }, {
        label: 'bottom',
        value: 'bottom'
      }],
      default: 'left'
    },
    defaultVisible: {
      title: $i18n.get({
        id: 'basic.components.SideTabs.registerMeta.DefaultExpansion',
        dm: '默认展开'
      }),
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    }
  }, metas);
  return schema;
};
export default registerMeta;