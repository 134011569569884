export function query(messages, apiKey, signal) {
  return fetch('https://api.openai.com/v1/chat/completions', {
    signal: signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(apiKey)
    },
    body: JSON.stringify({
      model: 'gpt-3.5-turbo',
      messages: messages.map(function (_ref) {
        var role = _ref.role,
          content = _ref.content;
        return {
          role: role,
          content: content
        };
      })
    })
  }).then(function (res) {
    return res.json();
  }).then(function (res) {
    return {
      status: 'success',
      message: res.choices[0].message
    };
  }).catch(function (error) {
    if (error.name === 'AbortError') return {
      status: 'cancel',
      message: null
    };
    return {
      status: 'failed',
      message: error.message
    };
  });
}