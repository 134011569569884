function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { extra } from '@antv/gi-sdk';
import info from './info';
import $i18n from '../../i18n';
var deepClone = extra.deepClone,
  GIAC_CONTENT_METAS = extra.GIAC_CONTENT_METAS;
var metas = deepClone(GIAC_CONTENT_METAS);
// @ts-ignore
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.title.default = info.name;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.icon.default = info.icon;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.tooltip.default = info.desc;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.containerWidth.default = '400px';
var registerMeta = function registerMeta(_ref) {
  var schemaData = _ref.schemaData,
    hasPropertyGraph = _ref.hasPropertyGraph;
  var nodeProperties = schemaData.nodes.reduce(function (acc, cur) {
    return Object.assign(Object.assign({}, acc), cur.properties);
  }, {});
  var edgeProperties = schemaData.edges.reduce(function (acc, cur) {
    return Object.assign(Object.assign({}, acc), cur.properties);
  }, {});
  var nodeOptions = Object.entries(nodeProperties).map(function (e) {
    var _e = _slicedToArray(e, 2),
      key = _e[0],
      value = _e[1];
    return {
      label: "node-".concat(key),
      value: "node-".concat(key)
    };
  });
  var edgeOptions = Object.entries(edgeProperties).map(function (e) {
    var _e2 = _slicedToArray(e, 2),
      key = _e2[0],
      value = _e2[1];
    return {
      label: "edge-".concat(key),
      value: "edge-".concat(key)
    };
  });
  var schema = Object.assign({
    filterKeys: {
      title: $i18n.get({
        id: 'basic.components.FilterPanel.registerMeta.DefaultFilterField',
        dm: '默认筛选字段'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        mode: 'multiple'
      },
      enum: [].concat(_toConsumableArray(nodeOptions), _toConsumableArray(edgeOptions)),
      default: []
    },
    enableInfoDetect: hasPropertyGraph ? {
      title: $i18n.get({
        id: 'basic.components.FilterPanel.registerMeta.SmartRecommendation',
        dm: '智能推荐'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    } : undefined,
    isFilterIsolatedNodes: {
      title: $i18n.get({
        id: 'basic.components.FilterPanel.registerMeta.FilterIsolatedNodes',
        dm: '过滤孤立节点'
      }),
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    },
    highlightMode: {
      title: $i18n.get({
        id: 'basic.components.FilterPanel.registerMeta.HighlightMode',
        dm: '高亮模式'
      }),
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    },
    filterLogic: {
      title: $i18n.get({
        id: 'basic.components.FilterPanel.registerMeta.FilteringLogic',
        dm: '筛选逻辑'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [{
        value: 'and',
        label: 'and'
      }, {
        value: 'or',
        label: 'or'
      }],
      default: 'and'
    },
    histogramOptions: {
      type: 'object',
      'x-decorator': 'FormItem',
      'x-component': 'FormCollapse.CollapsePanel',
      'x-component-props': {
        header: $i18n.get({
          id: 'basic.components.FilterPanel.registerMeta.SubBoxSetting',
          dm: '分箱设置'
        })
      },
      properties: {
        isCustom: {
          title: $i18n.get({
            id: 'basic.components.FilterPanel.registerMeta.CustomSubBox',
            dm: '自定义分箱'
          }),
          type: 'string',
          'x-decorator': 'FormItem',
          'x-component': 'Switch',
          default: false,
          'x-reactions': [{
            target: 'histogramOptions.min',
            fulfill: {
              state: {
                visible: '{{$self.value}}'
              }
            }
          }, {
            target: 'histogramOptions.max',
            fulfill: {
              state: {
                visible: '{{$self.value}}'
              }
            }
          }, {
            target: 'histogramOptions.binWidth',
            fulfill: {
              state: {
                visible: '{{$self.value}}'
              }
            }
          }]
        },
        min: {
          title: $i18n.get({
            id: 'basic.components.FilterPanel.registerMeta.IntervalMinimum',
            dm: '区间最小值'
          }),
          type: 'string',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: null
        },
        max: {
          title: $i18n.get({
            id: 'basic.components.FilterPanel.registerMeta.IntervalMaximum',
            dm: '区间最大值'
          }),
          type: 'string',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: null
        },
        binWidth: {
          title: $i18n.get({
            id: 'basic.components.FilterPanel.registerMeta.BinValue',
            dm: '分箱值'
          }),
          type: 'string',
          'x-decorator': 'FormItem',
          'x-component': 'NumberPicker',
          default: null
        }
      }
    }
  }, metas);
  if (!hasPropertyGraph) delete schema.enableInfoDetect;
  return schema;
};
export default registerMeta;