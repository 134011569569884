import $i18n from '../../i18n';
var info = {
  id: 'UadLayout',
  name: $i18n.get({
    id: 'basic.components.UadLayout.info.UpperAndLowerLayout',
    dm: '上下布局'
  }),
  desc: $i18n.get({
    id: 'basic.components.UadLayout.info.UpperAndLowerLayoutSuitable',
    dm: '上下布局，适用于图查询场景'
  }),
  icon: 'icon-pic-center',
  cover: 'http://xxxx.jpg',
  category: 'container-components',
  type: 'GICC_LAYOUT',
  docs: 'https://www.yuque.com/antv/gi/ya1sna6e8w4opstp'
};
export default info;