import { Utils } from '@antv/graphin';
import { notification } from 'antd';
import $i18n from '../i18n';
export var CypherQuery = {
  name: $i18n.get({
    id: 'advance.src.services.CypherQuery.StatementQuery',
    dm: '语句查询'
  }),
  service: function service(params) {
    console.log($i18n.get({
      id: 'advance.src.services.CypherQuery.QueryParameters',
      dm: '查询参数'
    }), params);
    var id = 'mock_language_query';
    var data = Utils.mock(Math.round(Math.random() * 100)).tree().graphin();
    notification.info({
      message: $i18n.get({
        id: 'advance.src.services.CypherQuery.QuerySucceeded',
        dm: '查询成功'
      }),
      description: $i18n.get({
        id: 'advance.src.services.CypherQuery.TheCurrentQueryRequestIs',
        dm: '当前的查询请求是 MOCK 的，请搭配图数据库使用。'
      })
    });
    return new Promise(function (resolve) {
      return resolve(data);
    });
  }
};