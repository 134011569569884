/**
 *
 * @param path 由边构成的路径
 * @param  weightPropertyName 权重
 * @param graphData 图数据
 * @return 路径长度
 */
export var getPathByWeight = function getPathByWeight(path, weightPropertyName, sourceDataMap) {
  var pathLen = 0;
  path.forEach(function (edgeId) {
    var edgeConfig = sourceDataMap.edges[edgeId];
    var data = edgeConfig.data;
    pathLen = pathLen + data[weightPropertyName] ? data[weightPropertyName] : 0;
  });
  return pathLen;
};