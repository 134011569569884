import $i18n from '../i18n';
export var GremlinQuery = {
  name: $i18n.get({
    id: 'advance.src.services.GremlinQuery.GremlinQuery',
    dm: 'Gremlin 查询'
  }),
  service: function service(localData) {
    var id = 'mock_gremlin_query';
    var data = {
      nodes: [{
        id: id,
        type: 'user'
      }, {
        id: "".concat(id, "-family"),
        type: 'user'
      }, {
        id: "".concat(id, "-card"),
        type: 'card'
      }],
      edges: [{
        source: id,
        target: "".concat(id, "-family"),
        type: 'family'
      }, {
        source: id,
        target: "".concat(id, "-card"),
        type: 'own'
      }]
    };
    return new Promise(function (resolve) {
      return resolve({
        success: true,
        data: data
      });
    });
  }
};