import $i18n from '../../i18n';
var info = {
  id: 'GroupBar',
  name: $i18n.get({
    id: 'basic.components.GroupBar.info.GroupOperationColumn',
    dm: '分组操作栏'
  }),
  desc: $i18n.get({
    id: 'basic.components.GroupBar.info.GroupOperationsColumnGroupAssets',
    dm: '分组操作栏,分组形式集成资产'
  }),
  icon: 'icon-tabs',
  cover: 'http://xxxx.jpg',
  category: 'container-components',
  type: 'GICC',
  docs: 'https://www.yuque.com/antv/gi/hnrvecakwszcgbgg'
};
export default info;