export function validateWebSocketURL(url) {
  // 检查URL是否以ws://或wss://开头
  var regex = /^(ws|wss):\/\//;
  if (!regex.test(url)) {
    return false;
  }
  // 使用URL对象进行更详细的验证
  try {
    var parsedUrl = new URL(url);
    if (parsedUrl.protocol !== 'ws:' && parsedUrl.protocol !== 'wss:') {
      return false;
    }
  } catch (error) {
    return false;
  }
  return true;
}
/**
 * 基于正则表达式验证文本是否满足图数据格式
 * . 1,jim
 * . 2,tom
 * - 1,2,0.2
 */
export function validateGraphData(data) {
  var regexNode = /^\.\s\d{1,2},[a-zA-Z]+$/;
  var regexEdge = /^- \d+,\d+,\d+(\.\d+)?$/;
  var lines = data.split('\n');
  for (var i = 0; i < lines.length; i++) {
    var line = lines[i];
    if (!regexNode.test(line) && !regexEdge.test(line)) {
      return false;
    }
  }
  return true;
}