function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { icons } from '@antv/gi-sdk';
export var handlePinNode = function handlePinNode(target, graph, restartForceSimulation, params) {
  var _ref = params || {},
    x = _ref.x,
    y = _ref.y,
    dragNodeMass = _ref.dragNodeMass,
    isForce = _ref.isForce;
  var model = target.getModel();
  try {
    // 目前仅支持GraphinNode 节点
    var isGraphinNode = model.type === 'graphin-circle';
    if (!isGraphinNode) {
      return;
    }
    var style = model.style || {
      badges: []
    };
    var keyshape = style.keyshape,
      icon = style.icon;
    var badges = _toConsumableArray(style.badges);
    var index = badges.findIndex(function (_ref2) {
      var value = _ref2.value;
      return value === icons.pushpin;
    });
    badges.splice(index, 1); //   delete default pin badge
    badges.push({
      position: 'LB',
      fontFamily: 'iconfont',
      type: 'font',
      value: icons.pushpin,
      size: [15, 15],
      color: keyshape.fill,
      fill: icon.fill,
      stroke: keyshape.fill
    });
    // update style
    graph.updateItem(target, {
      pinned: true,
      mass: dragNodeMass,
      style: {
        badges: badges
      }
    });
    //  如果是力导，需要重新启动
    if (isForce) {
      var _model = target.get('model');
      var position = x && y && {
        x: x,
        y: y
      };
      var newModel = Object.assign(Object.assign(Object.assign({}, _model), position), {
        pinned: true,
        forceMass: dragNodeMass,
        mass: dragNodeMass,
        layout: {
          force: Object.assign(Object.assign({}, _model.force), {
            mass: dragNodeMass
          })
        }
      });
      // 重启力导
      restartForceSimulation([newModel], graph);
      // simulation.restart([newModel], graph);
    }
  } catch (error) {
    console.log('error', error);
  }
};
export var handleUnPinNode = function handleUnPinNode(target, graph, restartForceSimulation, isForce) {
  try {
    var model = target.getModel();
    // 目前仅支持GraphinNode 节点
    var isGraphinNode = model.type === 'graphin-circle';
    if (!isGraphinNode) {
      return;
    }
    var style = model.style || {
      badges: []
    };
    var badges = _toConsumableArray(style.badges);
    var index = badges.findIndex(function (_ref3) {
      var value = _ref3.value;
      return value === icons.pushpin;
    });
    badges.splice(index, 1);
    // 更改样式
    graph.updateItem(model.id, {
      layout: Object.assign(Object.assign({}, model.layout), {
        force: {
          mass: null
        }
      }),
      mass: 1,
      pinned: false,
      style: {
        badges: badges
      }
    });
    // 重启力导
    if (isForce) {
      restartForceSimulation([model], graph);
    }
  } catch (error) {
    console.log(error);
  }
};