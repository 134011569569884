import $i18n from '../../i18n';
export default {
  id: 'LoopDetectionDemo',
  name: $i18n.get({
    id: 'tugraph-analytics.components.LoopDetectionDemo.info.LoopDetectionDemo',
    dm: '环路检测Demo'
  }),
  desc: $i18n.get({
    id: 'tugraph-analytics.components.LoopDetectionDemo.info.LoopDetectionDemo',
    dm: '环路检测Demo'
  }),
  cover: 'http://xxx.jpg',
  category: 'data-analysis',
  icon: 'icon-sync',
  type: 'GIAC_CONTENT'
};