import { LAYOUTS, LayoutMap } from './const';
/**
 * 根据目标节点生成子图
 * @param graphData 画布图数据
 * @param targetNodes 目标节点
 * @returns 生成的子图
 */
export var cropGraphByNodes = function cropGraphByNodes(graphData, targetNodes) {
  var edges = graphData.edges,
    nodes = graphData.nodes;
  var ids = targetNodes.map(function (node) {
    return node.id;
  });
  var newEdges = edges.filter(function (edge) {
    var source = edge.source,
      target = edge.target;
    if (ids.indexOf(source) !== -1 && ids.indexOf(target) !== -1) {
      return true;
    }
    return false;
  });
  var newNodes = nodes.filter(function (node) {
    return ids.indexOf(node.id) !== -1;
  }).map(function (node) {
    var _a, _b;
    // 映射节点大小，用于圆形布局防重叠：https://github.com/antvis/layout/blob/master/src/layout/circular.ts#L213
    node.size = ((_b = (_a = node.style) === null || _a === void 0 ? void 0 : _a.keyshape) === null || _b === void 0 ? void 0 : _b.size) || 26;
    /*
      注意：这里不能使用 return {...node, size: node.style?.keyshape.size || 26}，
      因为我们需要将原来的那批节点返回，进行布局转化
    */
    return node;
  });
  return {
    nodes: newNodes,
    edges: newEdges
  };
};
/**
 * 更新布局
 * @param layouts 布局信息
 * @param graph G6 graph 实例
 * @param gap 子图之间的间隙
 * @returns
 */
export var updateLayout = function updateLayout(layouts, graph, gap, direction) {
  var count = layouts.length;
  var source = graph.save();
  var width = graph.get('width') / count;
  var height = graph.get('height') / count;
  layouts
  //过滤掉节点为空的布局
  .filter(function (lay) {
    return lay.nodes.length !== 0;
  }).forEach(function (layout, index) {
    var type = layout.type,
      options = layout.options,
      nodes = layout.nodes;
    var center;
    if (direction === 'horizontal') {
      center = [gap / 2 + index * gap, height / 2];
    } else {
      center = [width / 2, gap + index * gap];
    }
    var layoutOptions = Object.assign({
      width: width,
      height: height,
      center: center
    }, options);
    var instance = new LayoutMap[type](layoutOptions);
    var newGraphData = cropGraphByNodes(source, nodes);
    instance.layout(newGraphData);
  });
  graph.once('afteranimate', function () {
    graph.fitView();
  });
  graph.positionsAnimate();
};
export var getLayoutOptions = function getLayoutOptions(id, graph, data, subGraphNodes) {
  var _a;
  var wholeGraph = subGraphNodes.length === data.nodes.length;
  var layoutOptions = ((_a = LAYOUTS.find(function (layout) {
    return layout.value === id.options;
  })) === null || _a === void 0 ? void 0 : _a.options) || {};
  var graphWidth = graph.getWidth();
  var graphHeight = graph.getHeight();
  if (id === 'circular') {
    var radius = subGraphNodes.length * 60 / (2 * Math.PI);
    if (wholeGraph) {
      radius = Math.min(graphWidth, graphHeight) / 2;
    }
    layoutOptions.radius = radius;
  }
  if (wholeGraph) {
    layoutOptions.width = graphWidth;
    layoutOptions.height = graphHeight;
  }
  return layoutOptions;
};