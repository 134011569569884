/**
 * 计算字符串的长度
 * @param {string} str 指定的字符串
 * 判断是否为汉字，汉字长度为2
 */
/* eslint-disable no-plusplus */
function calcStrLen(str) {
  var len = 0;
  var lenList = [];
  for (var i = 0; i < (str === null || str === void 0 ? void 0 : str.length); i++) {
    if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 128) {
      len++;
    } else {
      len += 2;
    }
    //@ts-ignore
    lenList.push(len);
  }
  return {
    len: len,
    lenList: lenList
  };
}
export var fittingString = function fittingString(str, maxWidth, fontSize) {
  // 英文宽度平均为 .5 fontsize + 0.5的边距
  // extraWidth 保证箭头出现。同时为字体宽度增加容错
  var fontWidth = fontSize / 2 + 0.5; // 字号+边距
  var extraWidth = 75;
  var contentWidth = maxWidth - extraWidth;
  var _calcStrLen = calcStrLen(str),
    len = _calcStrLen.len,
    lenList = _calcStrLen.lenList;
  var width = len * fontWidth;
  var ellipsis = '…';
  // ellipsis 字体宽度
  var ellipsisWidth = 10;
  if (width > contentWidth) {
    var actualLen = Math.floor((contentWidth - ellipsisWidth) / fontWidth);
    var index = lenList.findIndex(function (strLen) {
      return strLen > actualLen;
    }) - 1;
    var result = str.substring(0, index) + ellipsis;
    // 有时候会计算错误，所以当返回的字符串已经所有原始字符串，则直接返回原始字符串
    if (result === "".concat(str).concat(ellipsis)) {
      return str;
    }
    return result;
  }
  return str;
};
export var createUuid = function createUuid() {
  return 'xxxxxxxxxxxx'.replace(/[x]/g, function (c) {
    // eslint-disable-next-line
    var r = Math.random() * 16 | 0;
    // eslint-disable-next-line
    var v = c == 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
};
export default {
  fittingString: fittingString,
  createUuid: createUuid
};