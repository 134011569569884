import $i18n from '../../i18n';
var info = {
  id: 'Overview',
  name: $i18n.get({
    id: 'basic.components.OverView.info.BigPictureOverview',
    dm: '大图概览'
  }),
  desc: $i18n.get({
    id: 'basic.components.OverView.info.BigPictureOverview',
    dm: '大图概览'
  }),
  icon: 'icon-dashboard',
  cover: 'http://xxxx.jpg',
  category: 'system-interaction',
  type: 'GIAC_CONTENT',
  docs: 'https://www.yuque.com/antv/gi/vwybfglsys8dv5gs'
};
export default info;