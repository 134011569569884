function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { common } from '@antv/gi-sdk';
import { ext } from '@antv/matrix-util';
import { isNumber } from 'lodash';
var transform = ext.transform;
var scaleNodes = function scaleNodes(graphData) {
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 600;
  var _graphData$nodes = graphData.nodes,
    nodes = _graphData$nodes === void 0 ? [] : _graphData$nodes,
    _graphData$edges = graphData.edges,
    edges = _graphData$edges === void 0 ? [] : _graphData$edges;
  if (!(nodes === null || nodes === void 0 ? void 0 : nodes.length)) {
    return;
  }
  var minX = Infinity;
  var maxX = -Infinity;
  var minY = Infinity;
  var maxY = -Infinity;
  nodes.forEach(function (node) {
    if (node.x === undefined || node.y === undefined) {
      return;
    }
    if (node.x < minX) {
      minX = node.x;
    }
    if (node.x > maxX) {
      maxX = node.x;
    }
    if (node.y < minY) {
      minY = node.y;
    }
    if (node.y > maxY) {
      maxY = node.y;
    }
  });
  var wRatio = (maxX - minX) / value;
  var hRatio = (maxY - minY) / value;
  var ratio = Math.max(wRatio, hRatio);
  if (ratio < 1) {
    return {
      nodes: nodes,
      edges: edges
    };
  }
  return {
    nodes: nodes.map(function (node) {
      return Object.assign(Object.assign({}, node), {
        x: node.x ? node.x / ratio : undefined,
        y: node.y ? node.y / ratio : undefined
      });
    }),
    edges: edges
  };
};
/**
 * 将图上下平移，y 坐标聚焦到画布中心
 * @param graph 图实例
 * @param focusNode 需要聚焦的节点 item
 * @param height 图的高度
 */
export var focusNodeY = function focusNodeY(graph, focusNode, height) {
  var focusTo = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'center';
  var y = focusNode.getModel().y;
  var group = graph.getGroup();
  var matrix = group.getMatrix() || [1, 0, 0, 0, 1, 0, 0, 0, 1];
  var vy = 0;
  if (focusTo === 'top') {
    vy = -y;
  } else if (isNumber(focusTo)) {
    var focusY = graph.getPointByCanvas(0, focusTo).y;
    vy = focusY - y;
  } else {
    // center
    var centerY = graph.getPointByCanvas(0, height / 3).y;
    vy = centerY - y;
  }
  var dy = vy * matrix[4];
  var lastY = 0;
  var newY = 0;
  group.animate(function (ratio) {
    newY = dy * ratio;
    matrix = transform(matrix, [['t', 0, newY - lastY]]);
    lastY = newY;
    return {
      matrix: matrix
    };
  }, {
    duration: 500
  });
};
export var ADD_EDGE_ID = 'ADD_EDGE_ID';
export var addEdge = function addEdge(sourceId, graph) {
  var source = graph.findById(sourceId);
  var hasAddEdge = graph.findById(ADD_EDGE_ID);
  // 过滤已有添加边的情况
  if (!source || hasAddEdge) {
    return;
  }
  var _source$getBBox = source.getBBox(),
    centerX = _source$getBBox.centerX,
    centerY = _source$getBBox.centerY,
    width = _source$getBBox.width;
  var initTargetPoint = {
    x: centerX + width / 2 + 25,
    y: centerY
  };
  // 使用 CubicEdge 方便表示直线和临时自环
  var edgeModel = {
    id: ADD_EDGE_ID,
    zIndex: -1,
    source: sourceId,
    target: initTargetPoint,
    type: 'quadratic',
    curveLevel: 0,
    style: {
      lineAppendWidth: 30,
      endArrow: true
    }
  };
  var edge = graph.addItem('edge', edgeModel);
  //@ts-ignore
  edge.toFront();
  source.toFront();
};
var exportGraphData = function exportGraphData(data) {
  var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'graph-data';
  if (!data || _typeof(data) !== 'object') {
    return;
  }
  var jsonText = JSON.stringify(data);
  common.createDownload(new Blob([jsonText], {
    type: 'text/json'
  }), fileName.includes('.json') ? fileName : "".concat(fileName, ".json"));
  return jsonText;
};
export function clearItemStates(graph, graphItem, states) {
  states.forEach(function (state) {
    if (graphItem === null || graphItem === void 0 ? void 0 : graphItem.hasState(state)) {
      graph.setItemState(graphItem, state, false);
      graphItem.refresh();
    }
  });
}
export var clearItemsStates = function clearItemsStates(graph, items, clearStates) {
  items.forEach(function (graphItem) {
    try {
      clearItemStates(graph, graphItem, clearStates);
    } catch (error) {
      console.log('error :>> ', graphItem, error);
    }
  });
};
export default {
  scaleNodes: scaleNodes,
  focusNodeY: focusNodeY,
  addEdge: addEdge,
  exportGraphData: exportGraphData,
  clearItemsStates: clearItemsStates,
  clearItemStates: clearItemStates
};