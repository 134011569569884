import { get } from '@antv/util';
export var getCurrentSortMethod = function getCurrentSortMethod(fieldName, sortParams) {
  return get((sortParams || []).filter(function (param) {
    return param.sortFieldId === fieldName;
  }), '[0].sortMethod', 'NONE') || 'NONE';
};
export var hasFilterParams = function hasFilterParams(fieldName, filterParams) {
  return !!(filterParams || []).filter(function (param) {
    var _a;
    return param.filterKey === fieldName && ((_a = param.filteredValues) === null || _a === void 0 ? void 0 : _a.length);
  }).length;
};
/**
 *
 * @param fieldName
 * @param filterParams
 * @returns
 */
export var getCurrentFilterParams = function getCurrentFilterParams(fieldName, filterParams) {
  return (filterParams || []).filter(function (param) {
    var _a;
    return param.filterKey === fieldName && ((_a = param.filteredValues) === null || _a === void 0 ? void 0 : _a.length);
  }).map(function (param) {
    return param.filteredValues;
  }).flat();
};
export var convertToObject = function convertToObject(values) {
  var initData = {};
  values.forEach(function (val) {
    initData[val] = true;
  });
  return initData;
};