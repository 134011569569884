import $i18n from '../../i18n';
var registerMeta = function registerMeta(context) {
  var _context$GIAC_MENU_IT = context.GIAC_MENU_ITEMS,
    GIAC_MENU_ITEMS = _context$GIAC_MENU_IT === void 0 ? [] : _context$GIAC_MENU_IT;
  GIAC_MENU_ITEMS.map(function (item) {
    return item.value;
  });
  return {
    /** 分类信息 */
    GI_CONTAINER: {
      title: $i18n.get({
        id: 'basic.components.ContextMenu.registerMeta.IntegratedComponents',
        dm: '可集成组件'
      }),
      type: 'string',
      enum: GIAC_MENU_ITEMS,
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        mode: 'multiple'
      },
      default: GIAC_MENU_ITEMS.map(function (item) {
        return item.value;
      })
    }
  };
};
export default registerMeta;