import $i18n from '../../i18n';
var info = {
  id: 'ForceSimulation',
  name: $i18n.get({
    id: 'basic.components.ForceSimulation.info.ForceGuideController',
    dm: '力导控制器'
  }),
  desc: $i18n.get({
    id: 'basic.components.ForceSimulation.info.YouCanStopOrRestart',
    dm: '可以停止/重启力导布局'
  }),
  icon: 'icon-play-circle',
  cover: 'http://xxxx.jpg',
  category: 'system-interaction',
  type: 'GIAC',
  docs: 'https://www.yuque.com/antv/gi/oeunl5g60zkimsqw'
};
export default info;