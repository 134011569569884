import { Message } from './message';
/**
 * 转换图的数据信息为自然语言
 */
export function schemaToNL(schema) {
  var nodes = schema.nodes,
    edges = schema.edges;
  var nodesDescription = "\u56FE\u4E2D\u6709 ".concat(nodes.length, " \u79CD\u8282\u70B9\uFF0C\u5206\u522B\u662F\uFF1A").concat(nodes.map(function (node) {
    return "\"".concat(node.nodeType, "\"");
  }).join('，'), "\u3002");
  var edgesDescription = "\u56FE\u4E2D\u6709 ".concat(edges.length, " \u4E2D\u8FB9\uFF0C\u5206\u522B\u662F ").concat(edges.map(function (edge) {
    return "\"".concat(edge.edgeType, "\"");
  }).join('，'), "\u3002");
  return "".concat(nodesDescription, " ").concat(edgesDescription);
}
export function getPrompt(prompt, schema) {
  var graphSchema = schemaToNL(schema);
  return prompt.replace(/\$\{graphSchema\}/g, graphSchema);
}
export function getWelcomeMessage(welcome, prompt, schema) {
  return [prompt && new Message({
    status: 'success',
    role: 'system',
    content: getPrompt(prompt, schema),
    timestamp: Date.now(),
    reserved: true
  }), welcome && new Message({
    status: 'success',
    role: 'assistant',
    content: welcome,
    timestamp: Date.now(),
    reserved: true
  })].filter(Boolean);
}