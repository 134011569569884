import LargeGraph from './LargeGraph';
import MapMode from './MapMode';
// 需要基于V2版本重新研发，暂时先下线：https://github.com/hyqaimx/graph-timeline/tree/graphv2
// import Timeline from './Timeline';
import Timebar from './Timebar';
var components = {
  MapMode: MapMode,
  LargeGraph: LargeGraph,
  // Timeline,
  Timebar: Timebar
};
export { components };