import $i18n from '../../i18n';
var info = {
  id: 'ChartAnalysis',
  name: $i18n.get({
    id: 'basic.components.ChartAnalysis.info.ChartAnalysis',
    dm: '图表分析'
  }),
  desc: $i18n.get({
    id: 'basic.components.ChartAnalysis.info.TheDataInTheGraph',
    dm: '图中数据通过统计图表展示分析'
  }),
  icon: 'icon-barchart',
  cover: 'http://xxxx.jpg',
  category: 'data-analysis',
  type: 'GIAC_CONTENT',
  docs: 'https://www.yuque.com/antv/gi/pfhavf9e96n3n3a2'
};
export default info;