import { extra } from '@antv/gi-sdk';
import info from './info';
var GIAC_METAS = extra.GIAC_METAS,
  deepClone = extra.deepClone;
var metas = deepClone(GIAC_METAS);
metas.GIAC.properties.GIAC.properties.title.default = info.name;
metas.GIAC.properties.GIAC.properties.isShowTitle.default = false;
metas.GIAC.properties.GIAC.properties.icon.default = info.icon;
export default (function () {
  return Object.assign({}, metas);
});