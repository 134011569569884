export var highlightBySelectedNodes = function highlightBySelectedNodes(selectedNodes, context) {
  var updateContext = context.updateContext,
    largeGraphData = context.largeGraphData,
    graphData = context.data,
    graph = context.graph;
  if (largeGraphData) {
    var nodes = largeGraphData.nodes.filter(function (n) {
      return selectedNodes.has(n.id);
    });
    var edges = largeGraphData.edges.filter(function (e) {
      return selectedNodes.has(e.target) && selectedNodes.has(e.source);
    });
    var newData = {
      nodes: nodes,
      edges: edges
    };
    updateContext(function (draft) {
      draft.data = newData;
      draft.source = newData;
    });
  } else {
    graphData.nodes.forEach(function (nodeConfig) {
      var id = nodeConfig.id;
      var item = graph.findById(id);
      if (selectedNodes.has(id)) {
        item.hasState('disabled') && graph.setItemState(id, 'disabled', false);
        !item.hasState('selected') && graph.setItemState(id, 'selected', true);
      } else {
        !item.hasState('disabled') && graph.setItemState(id, 'disabled', true);
        item.hasState('selected') && graph.setItemState(id, 'selected', false);
      }
    });
    graphData.edges.forEach(function (edgeConfig) {
      var id = edgeConfig.id,
        source = edgeConfig.source,
        target = edgeConfig.target;
      graph.setItemState(id, 'disabled', true);
      var item = graph.findById(id);
      if (selectedNodes.has(target) && selectedNodes.has(source)) {
        // 两端节点都高亮时，对应的边也高亮
        !item.hasState('selected') && graph.setItemState(id, 'selected', true);
        item.hasState('disabled') && graph.setItemState(id, 'disabled', false);
      } else {
        !item.hasState('disabled') && graph.setItemState(id, 'disabled', true);
        item.hasState('selected') && graph.setItemState(id, 'selected', false);
      }
    });
  }
};
export var highlightBySelectedEdges = function highlightBySelectedEdges(selectedEdges, context) {
  var updateContext = context.updateContext,
    largeGraphData = context.largeGraphData,
    graphData = context.data,
    graph = context.graph;
  var relatedNodes = new Set();
  if (largeGraphData) {
    var edges = largeGraphData.edges.filter(function (e) {
      if (selectedEdges.has(e.id)) {
        relatedNodes.add(e.target);
        relatedNodes.add(e.source);
        return true;
      }
      return false;
    });
    var nodes = largeGraphData.nodes.filter(function (n) {
      return relatedNodes.has(n.id);
    });
    var newData = {
      nodes: nodes,
      edges: edges
    };
    updateContext(function (draft) {
      draft.source = newData;
      draft.data = newData;
    });
  } else {
    graphData.edges.forEach(function (edgeConfig) {
      var id = edgeConfig.id,
        GI_AGGREGATE_ID = edgeConfig.GI_AGGREGATE_ID;
      var item = graph.findById(id);
      if (selectedEdges.has(GI_AGGREGATE_ID ? GI_AGGREGATE_ID : id)) {
        item.hasState('disabled') && graph.setItemState(id, 'disabled', false);
        !item.hasState('selected') && graph.setItemState(id, 'selected', true);
        relatedNodes.add(edgeConfig.target);
        relatedNodes.add(edgeConfig.source);
      } else {
        !item.hasState('disabled') && graph.setItemState(id, 'disabled', true);
        item.hasState('selected') && graph.setItemState(id, 'selected', false);
      }
    });
    graphData.nodes.forEach(function (nodeConfig) {
      var id = nodeConfig.id;
      var item = graph.findById(id);
      //graph.setItemState(id, 'disabled', true);
      if (relatedNodes.has(id)) {
        // 与高亮节点相连的边也要高亮
        item.hasState('disabled') && graph.setItemState(id, 'disabled', false);
        !item.hasState('selected') && graph.setItemState(id, 'selected', true);
      } else {
        !item.hasState('disabled') && graph.setItemState(id, 'disabled', true);
        item.hasState('selected') && graph.setItemState(id, 'selected', false);
      }
    });
  }
};