
  /** 脚本生成的环境变量，请在 gi-site/scrripts/pre-build.mjs 中修改 **/
  export const NODE_ENV = 'undefined';
  export const BUILD_MODE = 'undefined';
  export const G6_VERSION = '4.8.14';
  export const GRAPHIN_VERSION = '2.7.27';
  export const G2PLOT_VERSION = '2.4.16';
  export const ANTD_VERSION = '4.24.8';
  export const GI_VERSION = '2.4.21';

  /** 脚本生成的环境变量，请在 gi-site/scrripts/pre-build.mjs 中修改 **/
  