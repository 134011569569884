import { isEmpty } from 'lodash-es';
export var getKeySteps = function getKeySteps(timebar, selection) {
  if (isEmpty(timebar)) {
    return {
      startKey: 0,
      endKey: 0,
      steps: 1
    };
  }
  var startKey = 0;
  var endKey = 0;
  for (var i = 0; i < timebar.length; i++) {
    if (timebar[i] === selection[0]) {
      startKey = i;
    }
    if (timebar[i] === selection[1]) {
      endKey = i;
    }
  }
  var steps = endKey - startKey;
  return {
    startKey: startKey,
    endKey: endKey,
    steps: steps
  };
};
export var getTimeInterval = function getTimeInterval(timebar, selection) {
  var timeWindowType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'moveTime';
  var playback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  if (timebar.length === 0) {
    return undefined;
  }
  var _getKeySteps = getKeySteps(timebar, selection),
    startKey = _getKeySteps.startKey,
    endKey = _getKeySteps.endKey,
    newSteps = _getKeySteps.steps;
  var lastKey = timebar.length - 1;
  // 到达尾部
  if (endKey === lastKey) {
    var start = timeWindowType === 'moveTime' ? timebar[0] : timebar[startKey];
    var end = timeWindowType === 'moveTime' ? timebar[newSteps] : timebar[startKey + 1];
    return [start, end];
  }
  // 移动时间窗口
  if (timeWindowType === 'moveTime') {
    if (playback) {
      var _start = timebar[0];
      var _end = timebar[newSteps];
      return [_start, _end];
    }
    var _start2 = timebar[startKey + 1];
    var _end2 = timebar[endKey + 1];
    return [_start2, _end2];
  } else {
    // 累积时间窗口
    if (playback) {
      var _start3 = timebar[startKey];
      var _end3 = timebar[startKey + 1];
      return [_start3, _end3];
    }
    var _start4 = timebar[startKey];
    var _end4 = timebar[endKey + 1];
    return [_start4, _end4];
  }
};