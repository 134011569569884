function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { uniqueElementsBy } from './common';
import { filterByRules } from './filterByRules';
import processEdges from './processEdges';
/**
 *
 * @param elementType 元素类型：node or edge
 * @param data 数据
 * @param config GISDK配置
 * @param ElementAssets 元素资产
 * @param reset 是否重置transform
 * @returns nodes or edges
 */
export var transDataByConfig = function transDataByConfig(elementType, data, config, ElementAssets, reset) {
  console.time("".concat(elementType.toUpperCase(), "_TRANS_COST"));
  var elementConfig = config[elementType];
  if (!elementConfig) {
    return {};
  }
  var elementData = data[elementType];
  if (elementType === 'edges') {
    // 先整体做个多边处理
    elementData = processEdges(elementData, {
      poly: 40,
      loop: 10
    });
  }
  var _elementConfig = _toArray(elementConfig),
    basicConfig = _elementConfig[0],
    otherConfigs = _elementConfig.slice(1);
  var filterElements = otherConfigs.map(function (item) {
    //@ts-ignore
    var id = item.id,
      expressions = item.expressions,
      logic = item.logic;
    if (!ElementAssets) {
      return [];
    }
    var Element = ElementAssets[id];
    var filterData = filterByRules(elementData, {
      logic: logic,
      expressions: expressions
    });
    return Element.registerTransform(filterData, item, reset);
  }).reduce(function (acc, curr) {
    return [].concat(_toConsumableArray(curr), _toConsumableArray(acc));
  }, []);
  var uniqueElements = uniqueElementsBy(filterElements, function (a, b) {
    return a.id === b.id;
  });
  var uniqueIds = uniqueElements.map(function (n) {
    return n.id;
  });
  //@ts-ignore
  var restElements = elementData.filter(function (n) {
    return uniqueIds.indexOf(n.id) === -1;
  });
  //@ts-ignore
  var restData = ElementAssets[basicConfig.id].registerTransform(restElements, basicConfig, reset);
  var nodes = [].concat(_toConsumableArray(uniqueElements), _toConsumableArray(restData));
  console.timeEnd("".concat(elementType.toUpperCase(), "_TRANS_COST"));
  return nodes;
};