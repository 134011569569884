import $i18n from '../../i18n';
var info = {
  id: 'ContextMenu',
  name: $i18n.get({
    id: 'basic.components.ContextMenu.info.RightClickMenu',
    dm: '右键菜单'
  }),
  desc: $i18n.get({
    id: 'basic.components.ContextMenu.info.RightClickTheMenuContainer',
    dm: '鼠标右键即可出现菜单容器'
  }),
  cover: 'http://xxxx.jpg',
  category: 'container-components',
  type: 'GICC_MENU',
  icon: 'icon-mouse',
  docs: 'https://www.yuque.com/antv/gi/slg2190e5bf9m1af'
};
export default info;