function looseJsonParse(obj) {
  return Function('"use strict";return (' + obj + ')')();
}
/**
 *
 * @param serviceConfig  服务端拿到的资产：Services
 * @param data  图数据
 * @returns
 */
var getServicesByConfig = function getServicesByConfig(serviceConfig, LOCAL_DATA) {
  return serviceConfig.map(function (s) {
    var id = s.id,
      content = s.content,
      mode = s.mode;
    var runtimeContent = (content === null || content === void 0 ? void 0 : content.split('export default')[1]) || content;
    var transFn = looseJsonParse(runtimeContent);
    return {
      id: id,
      service: function service() {
        for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
          params[_key] = arguments[_key];
        }
        return transFn.apply(void 0, params.concat([LOCAL_DATA]));
      }
    };
  });
};
export default getServicesByConfig;