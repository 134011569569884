import { useEffect } from 'react';
import Mousetrap from 'mousetrap';
/**
 * @description 绑定快捷键
 * @description Bind a key to a callback function.
 */
export var Shortcuts = function () {
  var handlers = new Map();
  var on = function on() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    var key = args[0],
      callback = args[1],
      action = args[2];
    if (!key || !callback) return;
    if (!handlers.has(callback)) {
      var wrapper = function wrapper(e, combo) {
        e.preventDefault();
        try {
          callback === null || callback === void 0 ? void 0 : callback(e, combo);
        } catch (_a) {}
      };
      handlers.set(callback, wrapper);
    }
    Mousetrap.bind(key, handlers.get(callback), action);
  };
  var off = function off() {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }
    var key = args[0],
      action = args[1];
    if (!key) return;
    Mousetrap.unbind(key, action);
  };
  var emit = function emit() {
    for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }
    var key = args[0],
      action = args[1];
    if (!key) return;
    Mousetrap.trigger(key, action);
  };
  var reset = function reset() {
    Mousetrap.reset();
  };
  return {
    on: on,
    off: off,
    emit: emit,
    reset: reset
  };
}();
/**
 * @example
 * useShortcuts('ctrl+s', () => {
 *  console.log('save');
 * });
 */
export var useShortcuts = function useShortcuts() {
  for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
    args[_key4] = arguments[_key4];
  }
  useEffect(function () {
    Shortcuts.on.apply(Shortcuts, args);
    return function () {
      var key = args[0],
        action = args[2];
      Shortcuts.off(key, action);
    };
  }, []);
};