import { utils } from '@antv/gi-sdk';
import $i18n from '../../i18n';
import info from './info';
export default (function (context) {
  var services = context.services,
    engineId = context.engineId;
  var _utils$getServiceOpti = utils.getServiceOptionsByEngineId(services, info.services[0], engineId),
    options = _utils$getServiceOpti.options,
    defaultValue = _utils$getServiceOpti.defaultValue;
  var _utils$getServiceOpti2 = utils.getServiceOptionsByEngineId(services, info.services[1], engineId),
    menuOptions = _utils$getServiceOpti2.options,
    defaultMenuValue = _utils$getServiceOpti2.defaultValue;
  return {
    serviceId: {
      title: $i18n.get({
        id: 'basic.components.NeighborsQuery.registerMeta.DataService',
        dm: '数据服务'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      default: defaultValue,
      'x-component-props': {
        options: options
      }
    },
    menuServiceId: {
      title: $i18n.get({
        id: 'basic.components.NeighborsQuery.registerMeta.ConditionalService',
        dm: '条件服务'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      default: defaultMenuValue,
      'x-component-props': {
        options: menuOptions
      }
    },
    degree: {
      title: $i18n.get({
        id: 'basic.components.NeighborsQuery.registerMeta.QueryableDegrees',
        dm: '限定度数'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        options: [{
          value: 1,
          label: 1
        }, {
          value: 2,
          label: 2
        }, {
          value: 3,
          label: 3
        }]
      },
      default: 3
    },
    isFocus: {
      title: $i18n.get({
        id: 'basic.components.NeighborsQuery.registerMeta.WhetherToFocusOnThe',
        dm: '是否聚焦到扩散点'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    },
    limit: {
      title: $i18n.get({
        id: 'basic.components.NeighborsQuery.registerMeta.LimitedQuantity',
        dm: '限制数量'
      }),
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      default: 100
    }
  };
});