function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import dayjs from 'dayjs';
import { getTimeFormat } from './panel/helper';
export function timeParser(time, timeGranularity) {
  if (typeof time === 'number') {
    if (time.toString().length === 10) return time * 1000;
    return time;
  }
  return dayjs(time, getTimeFormat(timeGranularity)).valueOf(); //new Date(time).getTime();
}
/**
 * 根据时间范围筛选图数据
 * 从 context graph 中读取图数据，然后根据时间粒度进行筛选
 * 由于无法识别 graphData 是播放过程更新的还是外部更新的
 * 因此对每个播放的节点/边都加上 __GI_PLAYING__ 标记，用于区分
 * @param data 图数据
 * @param range 时间范围
 * @param timeField 时间字段(边)
 * @param timeFieldNode 时间字段(节点，默认与边相同)
 * @returns
 */
export function dataFilter(data, range, timeGranularity, timeField, type) {
  var _ref;
  var parser = function parser(time) {
    return timeParser(time, timeGranularity);
  };
  var baseFiltered = data[type].filter(function (item) {
    if (!item.data[timeField]) return true;
    var time = parser(item.data[timeField]);
    return time >= parser(range[0]) && time <= parser(range[1]);
  });
  var another = data[type === 'nodes' ? 'edges' : 'nodes'];
  var addPlayingTag = function addPlayingTag(data) {
    var now = new Date().getTime();
    return data.map(function (datum) {
      return Object.assign(Object.assign({}, datum), {
        __GI_PLAYING__: now
      });
    });
  };
  return _ref = {}, _defineProperty(_ref, type, addPlayingTag(baseFiltered)), _defineProperty(_ref, type === 'nodes' ? 'edges' : 'nodes', addPlayingTag(another)), _ref;
}
/**
 * 获取图数据中的时间范围
 */
export function getTimeRange(data, timeGranularity, timeField) {
  var parser = function parser(t) {
    return timeParser(t, timeGranularity);
  };
  var times = data.map(function (datum) {
    return datum[timeField];
  }).filter(Boolean).map(parser);
  var timeRange = [Math.min.apply(Math, _toConsumableArray(times)), Math.max.apply(Math, _toConsumableArray(times))];
  return timeRange;
}
/**
 * 转换图数据为 G2 渲染数据
 * @param data 图数据
 */
export function dataTransform(data, type) {
  return data[type].map(function (node) {
    return node.data;
  });
}
/**
 * 判断图数据中是否有正在播放的节点/边
 * @param data 图数据
 * @returns 是否有正在播放的节点/边
 */
export function isPlayingData(data) {
  return data.nodes.some(function (node) {
    return node.__GI_PLAYING__;
  }) || data.edges.some(function (edge) {
    return edge.__GI_PLAYING__;
  });
}