import $i18n from '../i18n';
var info = {
  id: 'Timebar',
  name: $i18n.get({
    id: 'scene.src.Timebar.info.TimelineController',
    dm: '时间线控制器'
  }),
  desc: $i18n.get({
    id: 'scene.src.Timebar.info.TimelineController',
    dm: '时间线控制器'
  }),
  cover: 'http://xxx.jpg',
  category: 'scene-analysis',
  icon: 'icon-signal-fill',
  type: 'GIAC_CONTENT'
};
export default info;