import $i18n from '../../i18n';
var info = {
  id: 'PropertyGraphInitializer',
  name: $i18n.get({
    id: 'basic.components.PropertyGraphInitializer.info.AttributeGraphCalculation',
    dm: '属性图计算'
  }),
  desc: $i18n.get({
    id: 'basic.components.PropertyGraphInitializer.info.AssistOtherAssetsToIntelligently',
    dm: '辅助其他资产智能分析本地数据的属性图计算'
  }),
  // icon: 'icon-export',
  cover: 'http://xxxx.jpg',
  category: 'algorithm-analysis',
  type: 'AUTO',
  // 申明需要实现的服务名
  services: [],
  docs: 'https://www.yuque.com/antv/gi/unt2grz4s5waqe0c'
};
export default info;