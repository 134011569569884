function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var walkProperties = function walkProperties(data) {
  var result = [];
  var walk = function walk(obj) {
    Object.keys(obj).forEach(function (key) {
      var value = obj[key];
      var isObject = Object.prototype.toString.call(value) === '[object Object]';
      if (isObject) {
        walk(value);
      } else {
        //@ts-ignore
        result.push(key);
      }
    });
  };
  walk(data);
  return _toConsumableArray(new Set(result));
};
export var getKeysByData = function getKeysByData(data, category) {
  try {
    if (category === 'node') {
      var node = data.nodes[0] || {};
      var result = walkProperties(node);
      return result;
    }
    if (category === 'edge') {
      var edge = data.edges[0] || {};
      var _result = walkProperties(edge);
      return _result;
    }
  } catch (error) {
    return ['id'];
  }
};
export var stringify = function stringify(obj) {
  var placeholder = '____PLACEHOLDER____';
  var fns = [];
  var json = JSON.stringify(obj, function (key, value) {
    if (typeof value === 'function') {
      //@ts-ignore
      fns.push(value);
      return placeholder;
    }
    return value;
  }, 2);
  //@ts-ignore
  json = json.replace(new RegExp('"' + placeholder + '"', 'g'), function (_) {
    return fns.shift();
  });
  return 'export default ' + json + '';
};