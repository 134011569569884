import $i18n from '../../i18n';
var info = {
  id: 'SelectExchangeMenuItem',
  name: $i18n.get({
    id: 'basic.components.SelectExchangeMenuItem.info.Inverse',
    dm: '反选'
  }),
  desc: $i18n.get({
    id: 'basic.components.SelectExchangeMenuItem.info.InverseSelectedNodes',
    dm: '反选已选择节点'
  }),
  icon: 'icon-revert-select',
  cover: 'http://xxxx.jpg',
  category: 'canvas-interaction',
  type: 'GIAC_MENU',
  docs: 'https://www.yuque.com/antv/gi/nqffpzvargy7ahox'
};
export default info;