import $i18n from '../../i18n';
var info = {
  id: 'LayoutSwitch',
  name: $i18n.get({
    id: 'basic.components.LayoutSwitch.info.LayoutSwitching',
    dm: '布局切换'
  }),
  desc: $i18n.get({
    id: 'basic.components.LayoutSwitch.info.SwitchLayoutWithOneClick',
    dm: '一键切换布局'
  }),
  icon: 'icon-layout',
  cover: 'http://xxxx.jpg',
  category: 'canvas-interaction',
  type: 'GIAC',
  docs: 'https://www.yuque.com/antv/gi/kehgv931z5v1fmg8'
};
export default info;