import { S2Event } from '@antv/s2';
import React from 'react';
import { useContext } from '@antv/gi-sdk';
import { highlightBySelectedEdges, highlightBySelectedNodes } from '../utils/highlight';
var useCellSelect = function useCellSelect(isSelectedActive, s2Instance, isFullScreen) {
  var context = useContext();
  var graphData = context.data,
    graph = context.graph,
    largeGraphData = context.largeGraphData,
    updateContext = context.updateContext;
  var nodeTable = s2Instance.nodeTable,
    edgeTable = s2Instance.edgeTable;
  React.useEffect(function () {
    if (nodeTable) {
      /** 圈选点表 */
      nodeTable.on(S2Event.GLOBAL_SELECTED, function () {
        // isSelectedActiv 为 false 或全屏时，不高亮选中元素
        if (!isSelectedActive || isFullScreen) {
          return;
        }
        var cells = nodeTable.interaction.getCells();
        var selectedNodes = new Set();
        cells.forEach(function (cell) {
          var _a;
          var rowIndex = cell.rowIndex;
          // @ts-ignore
          var rowData = nodeTable.dataSet.getMultiData();
          if (!rowData) return;
          var nodeID = (_a = rowData[rowIndex]) === null || _a === void 0 ? void 0 : _a.id;
          selectedNodes.add(nodeID);
        });
        highlightBySelectedNodes(selectedNodes, {
          updateContext: updateContext,
          largeGraphData: largeGraphData,
          data: graphData,
          graph: graph
        });
      });
    }
    if (edgeTable) {
      /** 圈选边表 */
      edgeTable.on(S2Event.GLOBAL_SELECTED, function () {
        // isSelectedActiv 为 false 或全屏时，不高亮选中元素
        if (!isSelectedActive || isFullScreen) {
          return;
        }
        var cells = edgeTable.interaction.getCells();
        var selectedEdges = new Set();
        cells.forEach(function (cell) {
          var rowIndex = cell.rowIndex;
          // @ts-ignore
          var rowData = edgeTable.dataSet.getMultiData();
          if (!rowData) return;
          var rd = rowData[rowIndex];
          var edgeID = rd.id,
            GI_AGGREGATE_ID = rd.GI_AGGREGATE_ID;
          selectedEdges.add(GI_AGGREGATE_ID ? GI_AGGREGATE_ID : edgeID);
        });
        highlightBySelectedEdges(selectedEdges, {
          updateContext: updateContext,
          largeGraphData: largeGraphData,
          data: graphData,
          graph: graph
        });
      });
    }
    /** 通过 postMessage 通信的 */
    var handleNodesCellMessage = function handleNodesCellMessage(e) {
      var _e$data = e.data,
        payload = _e$data.payload,
        type = _e$data.type;
      /** ${Onwer}_${ComponentName}_${Action} */
      if (type === 'GI_TABLEMODE_SELECT' && payload && payload.selectedNodes) {
        highlightBySelectedNodes(payload.selectedNodes, {
          updateContext: updateContext,
          largeGraphData: largeGraphData,
          data: graphData,
          graph: graph
        });
      }
    };
    var handleEdgesCellMessage = function handleEdgesCellMessage(e) {
      var _e$data2 = e.data,
        payload = _e$data2.payload,
        type = _e$data2.type;
      if (type === 'GI_TABLEMODE_SELECT' && payload.selectedEdges) {
        highlightBySelectedEdges(payload.selectedEdges, {
          updateContext: updateContext,
          largeGraphData: largeGraphData,
          data: graphData,
          graph: graph
        });
      }
    };
    window.addEventListener('message', handleNodesCellMessage);
    window.addEventListener('message', handleEdgesCellMessage);
    return function () {
      nodeTable === null || nodeTable === void 0 ? void 0 : nodeTable.off(S2Event.GLOBAL_SELECTED);
      edgeTable === null || edgeTable === void 0 ? void 0 : edgeTable.off(S2Event.GLOBAL_SELECTED);
      window.removeEventListener('message', handleEdgesCellMessage);
      window.removeEventListener('message', handleNodesCellMessage);
    };
  }, [isSelectedActive, largeGraphData, graphData, nodeTable, edgeTable, isFullScreen, updateContext, graph]);
};
export default useCellSelect;