var getCurrentLocales = function getCurrentLocales() {
  var locales = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var lang = arguments.length > 1 ? arguments[1] : undefined;
  var currentLocales = {
    language: lang
  };
  if (locales[lang]) {
    currentLocales = locales[lang];
  } else {
    Object.keys(locales).forEach(function (key) {
      if (locales[key].language === lang) {
        currentLocales = locales[key];
      }
    });
  }
  return currentLocales;
};
export default getCurrentLocales;