import { useContext } from '@antv/gi-sdk';
import React from 'react';
// 生成边表的数据
var useEdgeDataCfg = function useEdgeDataCfg() {
  var _useContext = useContext(),
    schemaData = _useContext.schemaData,
    graphData = _useContext.source,
    largeGraphData = _useContext.largeGraphData;
  var edgeDataCfg = React.useMemo(function () {
    var edgeProperties = schemaData.edges.reduce(function (acc, cur) {
      return Object.assign(Object.assign({}, acc), cur.properties);
    }, {});
    var columns = [];
    for (var key in edgeProperties) {
      if (typeof edgeProperties[key] === 'number' || typeof edgeProperties[key] === 'string') {
        columns.push(key);
      }
    }
    /*
      在大图模式下，表格的数据源为 largeGraphData
      此时刷选和点选表格时会过滤画布数据
    */
    var data;
    if (largeGraphData) {
      data = largeGraphData.edges.map(function (edge) {
        return Object.assign(Object.assign({}, edge.data), {
          id: edge.id
        });
      });
    } else {
      data = graphData.edges.map(function (edge) {
        return Object.assign(Object.assign({}, edge.data), {
          id: edge.id
        });
      });
    }
    return {
      fields: {
        columns: columns
      },
      data: data
    };
  }, [schemaData, graphData, largeGraphData]);
  return edgeDataCfg;
};
export default useEdgeDataCfg;