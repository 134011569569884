import $i18n from '../../i18n';
var info = {
  id: 'ZoomStatus',
  name: $i18n.get({
    id: 'basic.components.ZoomStatus.info.ZoomStatus',
    dm: '缩放状态'
  }),
  desc: $i18n.get({
    id: 'basic.components.ZoomStatus.info.WhenScalingToAThreshold',
    dm: '缩放时到某个阈值添加状态'
  }),
  cover: 'http://xxxx.jpg',
  category: 'canvas-interaction',
  type: 'AUTO',
  docs: 'https://www.yuque.com/antv/gi/egs4rz70pc1anpsq'
};
export default info;