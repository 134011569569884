import React from 'react';
import { useContext } from '../context';
var FitCenterAfterMount = function FitCenterAfterMount() {
  var _useContext = useContext(),
    graph = _useContext.graph,
    layout = _useContext.layout;
  var timer;
  React.useEffect(function () {
    var type = layout.type;
    if (type === 'graphin-force' || type === 'force2') {
      return;
    }
    if (type === 'dagre') {
      timer = setTimeout(function () {
        graph.fitCenter(true);
      }, 200);
    } else {
      graph.fitCenter(true);
    }
    return function () {
      timer && clearTimeout(timer);
    };
  }, []);
  return null;
};
export default FitCenterAfterMount;