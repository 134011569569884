var getColumns = function getColumns(schemaData, type) {
  var schema = schemaData[type];
  //@ts-ignore
  var properties = schema.reduce(function (acc, cur) {
    return Object.assign(Object.assign({}, acc), cur.properties);
  }, {});
  var columns = [];
  for (var key in properties) {
    if (typeof properties[key] === 'number' || typeof properties[key] === 'string') {
      columns.push(key);
    }
  }
  return columns;
};
export default getColumns;