import $i18n from '../../i18n';
var registerMeta = function registerMeta(_ref) {
  var keys = _ref.keys,
    schemaData = _ref.schemaData;
  var _a, _b;
  var nodeProperties = schemaData.nodes.reduce(function (acc, cur) {
    return Object.assign(Object.assign({}, acc), cur.properties);
  }, {});
  var options = keys.filter(function (k) {
    return nodeProperties[k] === 'number';
  }).map(function (k) {
    return {
      label: k,
      value: k
    };
  });
  return {
    income: {
      title: $i18n.get({
        id: 'basic.layouts.FundForce.registerMeta.InflowFunds',
        dm: '流入资金'
      }),
      'x-component': 'Select',
      'x-decorator': 'FormItem',
      'x-component-props': {
        options: options
      },
      default: (_a = options[0]) === null || _a === void 0 ? void 0 : _a.value
    },
    outcome: {
      title: $i18n.get({
        id: 'basic.layouts.FundForce.registerMeta.OutflowFunds',
        dm: '流出资金'
      }),
      'x-component': 'Select',
      'x-decorator': 'FormItem',
      'x-component-props': {
        options: options
      },
      default: (_b = options[0]) === null || _b === void 0 ? void 0 : _b.value
    },
    isLog: {
      title: $i18n.get({
        id: 'basic.layouts.FundForce.registerMeta.LogMapping',
        dm: 'log 映射'
      }),
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      default: true
    },
    multiple: {
      title: $i18n.get({
        id: 'basic.layouts.FundForce.registerMeta.MultipleMapping',
        dm: '倍数映射'
      }),
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
      default: '0.1'
    }
  };
};
export default registerMeta;