import { ArrayCollapse, ArrayItems, DatePicker, FormItem, Input, NumberPicker, Radio, Select, Switch } from '@formily/antd';
import { createSchemaField } from '@formily/react';
import { SketchPicker } from 'react-color';
import ColorInput from '../CommonStyleSetting/ColorInput';
import GroupSelect from '../CommonStyleSetting/GroupSelect';
import IconPicker from '../CommonStyleSetting/IconPicker';
import IconSelector from '../CommonStyleSetting/IconSelector';
import { AssetCollapse, FormCollapse, Offset } from '../FormilyForm';
import ModifierPicker from './ModifierPicker';
var SchemaField = createSchemaField({
  components: {
    ArrayCollapse: ArrayCollapse,
    ArrayItems: ArrayItems,
    AssetCollapse: AssetCollapse,
    ColorInput: ColorInput,
    DatePicker: DatePicker,
    FormCollapse: FormCollapse,
    FormItem: FormItem,
    GroupSelect: GroupSelect,
    IconPicker: IconPicker,
    IconSelector: IconSelector,
    Input: Input,
    ModifierPicker: ModifierPicker,
    NumberPicker: NumberPicker,
    Offset: Offset,
    Radio: Radio,
    Select: Select,
    SketchPicker: SketchPicker,
    Switch: Switch
  }
});
export default SchemaField;