function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import G6 from '@antv/g6';
/**
 * 检查节点中是否有已参数化、未配置的参数
 * @param model 节点数据
 * @returns
 */
var hasUnconfiguredParam = function hasUnconfiguredParam(model) {
  var _model$parameterized = model.parameterized,
    parameterized = _model$parameterized === void 0 ? {} : _model$parameterized,
    _model$configured = model.configured,
    configured = _model$configured === void 0 ? {} : _model$configured,
    _model$content = model.content,
    content = _model$content === void 0 ? {} : _model$content,
    _model$params = model.params,
    params = _model$params === void 0 ? {} : _model$params;
  var unconfigureParam = Object.keys(parameterized).find(function (fieldName) {
    var value = params[fieldName];
    var isPart = content.type === 'query' && fieldName === 'value' || _typeof(value) === 'object';
    if (isPart) {
      var unconfiguredValue = parameterized[fieldName].find(function (item) {
        return !configured["".concat(fieldName, "-").concat(item.parameterName)];
      });
      return unconfiguredValue;
    }
    return !configured["".concat(fieldName, "-").concat(fieldName)];
  });
  return !!unconfigureParam;
};
/**
 * 绘制未配置的红点提示
 * @param model
 * @param group
 * @returns
 */
var addUnconfiguredTag = function addUnconfiguredTag(model, group) {
  var _model$size = model.size,
    size = _model$size === void 0 ? [102, 33] : _model$size;
  return group.addShape('circle', {
    attrs: {
      x: size[0] / 2 - 1,
      y: -size[1] / 2 + 2,
      r: 4,
      fill: '#f5222d',
      stroke: '#fff',
      lineWidth: 1
    },
    name: 'unconfigure-tag'
  });
};
/**
 * 绘制运行成功或失败的标志
 * @param keyShapeStyle
 * @param shapeType
 * @param group
 * @param type
 */
var addIcon = function addIcon(keyShapeStyle, shapeType, group, type) {
  var width = keyShapeStyle.width,
    r = keyShapeStyle.r;
  var iconCenter = {
    x: shapeType === 'rect' ? width / 2 + 14 : r + 14,
    y: 0
  };
  group.addShape('circle', {
    attrs: Object.assign(Object.assign({}, iconCenter), {
      r: 8,
      fill: type === 'success' ? '#52c41a' : '#f5222d',
      lineWidth: 0,
      opacity: 0
    }),
    name: "".concat(type, "-icon")
  }).animate({
    opacity: 1
  }, {
    duration: 300
  });
  group.addShape('path', {
    attrs: {
      path: type === 'success' ? [['M', iconCenter.x - 4, 0], ['L', iconCenter.x, 3], ['L', iconCenter.x + 4, -2]] : [['M', iconCenter.x - 3, -3], ['L', iconCenter.x + 3, 3], ['M', iconCenter.x - 3, 3], ['L', iconCenter.x + 3, -3]],
      stroke: '#fff',
      lineWidth: 2
    },
    name: "".concat(type, "-content")
  });
};
/**
 * 移除成功或失败的标志
 * @param group
 * @param type
 */
var removeIcon = function removeIcon(group, type) {
  var icon = group.find(function (child) {
    return child.get('name') === "".concat(type, "-icon");
  });
  var content = group.find(function (child) {
    return child.get('name') === "".concat(type, "-content");
  });
  icon === null || icon === void 0 ? void 0 : icon.remove(true);
  content === null || content === void 0 ? void 0 : content.remove(true);
};
/**
 * 状态变化的样式响应
 * @param name
 * @param value
 * @param item
 * @param shapeType
 */
var _setState = function setState(name, value, item, shapeType) {
  var group = item.getContainer();
  var keyShape = item.getKeyShape();
  var keyShapeStyle = keyShape.attr();
  var width = keyShapeStyle.width,
    r = keyShapeStyle.r;
  if (name === 'running') {
    if (value) {
      if (shapeType === 'circle') {
        group.addShape('text', {
          attrs: {
            x: r + 4,
            y: 0,
            text: 'Ready',
            textAlign: 'left',
            textBaseline: 'middle',
            fontSize: 12,
            fill: '#52c41a'
          },
          name: 'running-icon'
        });
      } else {
        var matrix = G6.Util.transform([1, 0, 0, 0, 1, 0, 0, 0, 1], [['t', -(width / 2 + 14), 0], ['r', Math.PI + 0.9], ['t', width / 2 + 14, 0]]);
        group.addShape('circle', {
          attrs: {
            x: width / 2 + 14,
            y: 0,
            r: 6,
            lineWidth: 2,
            fill: '#fff',
            stroke: '#52c41a',
            lineDash: [4, 16 * Math.PI],
            matrix: matrix
          },
          name: 'running-icon'
        }).animate({
          lineDash: [4, 0]
        }, {
          duration: 1000,
          easing: 'easeLinear',
          repeat: true
        });
      }
    } else {
      var backShape = group.find(function (child) {
        return child.get('name') === 'running-icon';
      });
      if (backShape) {
        backShape.stopAnimate();
        backShape.remove(true);
      }
    }
  }
  if (name === 'success') {
    if (value) {
      if (shapeType === 'circle') {
        group.addShape('text', {
          attrs: {
            x: r + 4,
            y: 0,
            text: 'Ready',
            textAlign: 'left',
            textBaseline: 'middle',
            fontSize: 12,
            fill: '#52c41a'
          },
          name: 'success-icon'
        });
      } else {
        addIcon(keyShapeStyle, shapeType, group, 'success');
      }
    } else {
      removeIcon(group, 'success');
    }
  } else if (name === 'configuring') {
    if (value) {
      var style = Object.assign(Object.assign({}, keyShape.attr()), {
        stroke: '#fff',
        lineWidth: 1,
        fillOpacity: 0
      });
      keyShape.attr({
        lineWidth: 4,
        stroke: style.fill
      });
      delete style.fill;
      group.addShape(shapeType, {
        attrs: style,
        name: 'border'
      });
      var dot = group.find(function (child) {
        return child.get('name') === 'unconfigure-tag';
      });
      dot === null || dot === void 0 ? void 0 : dot.toFront();
    } else {
      var border = group.find(function (child) {
        return child.get('name') === 'border';
      });
      if (border) border.remove(true);
      keyShape.attr({
        lineWidth: 0
      });
    }
  } else if (name === 'error') {
    if (value) {
      addIcon(keyShapeStyle, shapeType, group, 'error');
    } else {
      removeIcon(group, 'error');
    }
  }
};
export var registerNodes = function registerNodes() {
  /**
   * 历史记录节点的自定义节点
   */
  G6.registerNode('gi-history-rect', {
    afterDraw: function afterDraw(model, group) {
      if ((model === null || model === void 0 ? void 0 : model.isConfigure) && hasUnconfiguredParam(model)) addUnconfiguredTag(model, group);
    },
    afterUpdate: function afterUpdate(model, item) {
      if (!model || !item) return;
      var group = item.getContainer();
      var tag = group.find(function (ele) {
        return ele.get('name') === 'unconfigure-tag';
      });
      if (model.isConfigure && hasUnconfiguredParam(model)) {
        if (!tag) addUnconfiguredTag(model, group);
      } else {
        if (tag) tag.remove();
      }
    },
    setState: function setState(name, value, item) {
      return _setState(name, value, item, 'rect');
    }
  }, 'rect');
  /**
   * 起点和终点的自定义节点
   */
  G6.registerNode('gi-history-circle', {
    setState: function setState(name, value, item) {
      return _setState(name, value, item, 'circle');
    }
  }, 'circle');
};