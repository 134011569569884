function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
export function parseLoopData(data) {
  var loops = [];
  data.forEach(function (loop) {
    var loopArr = loop.split(',');
    loops.push(loopArr);
  });
  return loops;
}
/**
 * @example
 * 节点
 * . 1,jim
 * . 2,tom
 * 边
 * - 1,2,0.2
 * 环路
 * 1,2,1
 */
export function parseGraphData(data) {
  if (typeof data !== 'string') return {
    nodes: [],
    edges: [],
    loops: []
  };
  var lines = data.split('\n');
  var nodes = [];
  var edges = [];
  var loops = [];
  lines.forEach(function (line) {
    var _line$split = line.split(' '),
      _line$split2 = _slicedToArray(_line$split, 2),
      type = _line$split2[0],
      val = _line$split2[1];
    if (type === '.') {
      var _val$split = val.split(','),
        _val$split2 = _slicedToArray(_val$split, 2),
        id = _val$split2[0],
        name = _val$split2[1];
      nodes.push({
        id: id,
        name: name
      });
    } else if (type === '-') {
      var _val$split3 = val.split(','),
        _val$split4 = _slicedToArray(_val$split3, 3),
        source = _val$split4[0],
        target = _val$split4[1],
        weight = _val$split4[2];
      edges.push({
        source: source,
        target: target,
        weight: Number(weight)
      });
    } else {
      var loop = type.split(',');
      loops.push(loop);
    }
  });
  return {
    nodes: nodes,
    edges: edges,
    loops: loops
  };
}