import $i18n from '../../i18n';
var info = {
  id: 'TableMode',
  name: $i18n.get({
    id: 'advance.components.TableMode.info.TableMode',
    dm: '表格模式'
  }),
  desc: $i18n.get({
    id: 'advance.components.TableMode.info.ShowNodesAndEdgesIn',
    dm: '将画布中的节点和边以表格形式展示'
  }),
  icon: 'icon-table',
  cover: 'http://xxxx.jpg',
  category: 'data-analysis',
  type: 'GIAC_CONTENT',
  docs: 'https://www.yuque.com/antv/gi/gs2fcb'
};
export default info;