/**
 *
 * @param source 原数据 格式 { type:"object",properties:{}}
 * @returns
 */
export var getMetaDefaultValues = function getMetaDefaultValues(s) {
  var ROOT = 'props';
  var result = {};
  var walk = function walk(schema, obj, k) {
    var type = schema.type,
      properties = schema.properties;
    if (type === 'void') {
      Object.keys(properties).forEach(function (key) {
        walk(properties[key], obj, key);
      });
      return;
    }
    if (type === 'object') {
      obj[k] = {};
      var val = obj[k];
      Object.keys(properties).forEach(function (key) {
        walk(properties[key], val, key);
      });
      return;
    }
    obj[k] = schema.default;
  };
  walk(s, result, ROOT);
  return result[ROOT];
};