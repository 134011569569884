import $i18n from '../../i18n';
var info = {
  id: 'DownLoad',
  name: $i18n.get({
    id: 'basic.components.DownLoad.info.Download',
    dm: '下载'
  }),
  desc: $i18n.get({
    id: 'basic.components.DownLoad.info.ClickToDownloadTheCanvas',
    dm: '点击下载画布图片'
  }),
  icon: 'icon-download',
  cover: 'http://xxxx.jpg',
  category: 'canvas-interaction',
  type: 'GIAC',
  docs: 'https://www.yuque.com/antv/gi/gvded87rggqt4m7f'
};
export default info;