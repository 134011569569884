import $i18n from '../i18n';
export var nodes = [{
  id: 'SimpleNode',
  default: true,
  name: $i18n.get({
    id: 'sdk.src.constants.template.OfficialNode',
    dm: '官方节点'
  }),
  expressions: [],
  groupName: $i18n.get({
    id: 'sdk.src.constants.template.DefaultStyle',
    dm: '默认样式'
  }),
  logic: true,
  props: {
    size: 26,
    color: '#ddd',
    label: []
  }
}];
export var edges = [{
  id: 'SimpleEdge',
  default: true,
  name: $i18n.get({
    id: 'sdk.src.constants.template.OfficialSide',
    dm: '官方边'
  }),
  expressions: [],
  groupName: $i18n.get({
    id: 'sdk.src.constants.template.DefaultStyle',
    dm: '默认样式'
  }),
  logic: true,
  props: {
    size: 1,
    color: '#ddd',
    label: ['source', 'target']
  }
}];
export var layout = {
  id: 'Force2',
  props: {
    type: 'force2',
    preset: {
      type: 'concentric'
    }
  }
};
export var components = [{
  id: 'ZoomIn',
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'sdk.src.constants.template.ZoomIn',
        dm: '放大'
      }),
      isShowIcon: true,
      icon: 'icon-zoomin',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '46px',
      isVertical: true
    }
  }
}, {
  id: 'ZoomOut',
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'sdk.src.constants.template.ZoomOut',
        dm: '缩小'
      }),
      isShowIcon: true,
      icon: 'icon-zoomout',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '46px',
      isVertical: true
    }
  }
}, {
  id: 'FitView',
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'sdk.src.constants.template.Adaptive',
        dm: '自适应'
      }),
      isShowIcon: true,
      icon: 'icon-fit-view',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '46px',
      isVertical: true
    }
  }
}, {
  id: 'FitCenterView',
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'sdk.src.constants.template.ViewAdaptiveCentering',
        dm: '视图自适应居中'
      }),
      isShowIcon: true,
      icon: 'icon-fit-center',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '46px',
      isVertical: true
    }
  }
}, {
  id: 'LassoSelect',
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'sdk.src.constants.template.FreeSelection',
        dm: '自由圈选'
      }),
      isShowIcon: true,
      icon: 'icon-lasso',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'sdk.src.constants.template.HoldDownShiftAndClick',
        dm: '按住Shift，点击画布即可自由圈选'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '46px',
      isVertical: true
    }
  }
}, {
  id: 'PropertiesPanel',
  props: {
    serviceId: 'GI/PropertiesPanel',
    title: $i18n.get({
      id: 'sdk.src.constants.template.PropertiesPanel',
      dm: '属性面板'
    }),
    placement: 'RT',
    width: '356px',
    height: 'calc(100% - 0px)',
    offset: [10, 10],
    animate: false
  }
}, {
  id: 'ActivateRelations',
  props: {
    enableNodeHover: true,
    enableEdgeHover: true,
    enable: true,
    trigger: 'click',
    upstreamDegree: 1,
    downstreamDegree: 1
  }
}, {
  id: 'CanvasSetting',
  props: {
    styleCanvas: {
      backgroundColor: '#fff',
      backgroundImage: 'https://gw.alipayobjects.com/mdn/rms_0d75e8/afts/img/A*k9t4QamMuQ4AAAAAAAAAAAAAARQnAQ'
    },
    dragCanvas: {
      disabled: false,
      direction: 'both',
      enableOptimize: true
    },
    zoomCanvas: {
      disabled: false,
      enableOptimize: true
    }
  }
}, {
  id: 'NodeLegend',
  props: {
    sortKey: 'type',
    textColor: '#ddd',
    placement: 'LB',
    offset: [100, 20]
  }
}, {
  id: 'Placeholder',
  name: $i18n.get({
    id: 'sdk.src.constants.template.CanvasPlaceholder',
    dm: '画布占位符'
  }),
  props: {
    img: 'https://mdn.alipayobjects.com/huamei_qa8qxu/afts/img/A*1BGfQ78mW4kAAAAAAAAAAAAADmJ7AQ/original',
    text: $i18n.get({
      id: 'sdk.src.constants.template.TheCurrentCanvasIsEmpty',
      dm: '当前暂无画布数据，请先试试「案例数据」'
    }),
    width: 340
  }
}, {
  id: 'FilterPanel',
  name: $i18n.get({
    id: 'sdk.src.constants.template.FilterPanel',
    dm: '筛选面板'
  }),
  props: {
    histogramColor: '#3056E3',
    isFilterIsolatedNodes: true,
    highlightMode: true,
    filterKeys: [],
    GI_CONTAINER_INDEX: 2,
    GIAC_CONTENT: {
      visible: false,
      disabled: false,
      isShowTitle: true,
      title: $i18n.get({
        id: 'sdk.src.constants.template.FilterPanel',
        dm: '筛选面板'
      }),
      isShowIcon: true,
      icon: 'icon-filter',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'sdk.src.constants.template.FilterCanvasInformationThroughAttributes',
        dm: '通过属性筛选画布信息，可自定义'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'top',
      hasDivider: false,
      height: '46px',
      isVertical: true,
      containerType: 'div',
      containerAnimate: false,
      containerPlacement: 'RT',
      offset: [0, 0],
      containerWidth: '400px',
      containerHeight: 'calc(100% - 100px)',
      contaienrMask: false
    }
  }
}, {
  id: 'LargeGraph',
  name: $i18n.get({
    id: 'sdk.src.constants.template.DBigPicture',
    dm: '3D大图'
  }),
  props: {
    visible: false,
    minSize: '50%',
    maxSize: '100%',
    placement: 'RB',
    offset: [0, 0],
    GI_CONTAINER_INDEX: 2,
    GIAC: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'sdk.src.constants.template.DBigPicture',
        dm: '3D大图'
      }),
      isShowIcon: true,
      icon: 'icon-3d',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '46px',
      isVertical: true
    }
  }
}, {
  id: 'MapMode',
  name: $i18n.get({
    id: 'sdk.src.constants.template.MapMode',
    dm: '地图模式'
  }),
  props: {
    visible: false,
    type: 'amap',
    theme: 'light',
    minSize: '50%',
    maxSize: '100%',
    placement: 'RB',
    offset: [0, 0],
    longitudeKey: 'longitude',
    latitudeKey: 'latitude',
    GI_CONTAINER_INDEX: 2,
    GIAC: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'sdk.src.constants.template.MapMode',
        dm: '地图模式'
      }),
      isShowIcon: true,
      icon: 'icon-global',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '46px',
      isVertical: true
    }
  }
}, {
  id: 'ContextMenu',
  name: $i18n.get({
    id: 'sdk.src.constants.template.RightClickMenu',
    dm: '右键菜单'
  }),
  props: {
    GI_CONTAINER: ['NeighborsQuery', 'ToggleClusterWithMenu', 'PinNodeWithMenu'],
    nodeMenuComponents: ['NeighborsQuery', 'ToggleClusterWithMenu', 'PinNodeWithMenu']
  }
}, {
  id: 'ToggleClusterWithMenu',
  name: $i18n.get({
    id: 'sdk.src.constants.template.ExpandFoldUp',
    dm: '展开/收起'
  }),
  props: {
    isReLayout: false,
    degree: 1
  }
}, {
  id: 'NeighborsQuery',
  name: $i18n.get({
    id: 'sdk.src.constants.template.NeighborQuery',
    dm: '邻居查询'
  }),
  props: {
    serviceId: 'GI/NeighborsQuery',
    degree: '1',
    isFocus: true
  }
}, {
  id: 'Copyright',
  name: $i18n.get({
    id: 'sdk.src.constants.template.Copyright',
    dm: '版权'
  }),
  props: {
    imageUrl: 'https://gw.alipayobjects.com/zos/bmw-prod/c2d4b2f5-2a34-4ae5-86c4-df97f7136105.svg',
    width: 200,
    height: 30,
    placement: 'RB',
    offset: [10, 10]
  }
}, {
  id: 'Loading',
  name: $i18n.get({
    id: 'sdk.src.constants.template.LoadAnimation',
    dm: '加载动画'
  }),
  props: {}
}, {
  id: 'PinNodeWithMenu',
  name: $i18n.get({
    id: 'sdk.src.constants.template.FixedNodeMenu',
    dm: '固定节点(MENU)'
  }),
  props: {
    color: '#fff',
    fill: '#262626'
  }
}, {
  id: 'ForceSimulation',
  name: $i18n.get({
    id: 'sdk.src.constants.template.ForceGuideLayoutController',
    dm: '力导布局控制器'
  }),
  props: {
    autoPin: true,
    dragNodeMass: 10000000,
    GI_CONTAINER_INDEX: 2,
    GIAC: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'sdk.src.constants.template.ForceGuideLayoutController',
        dm: '力导布局控制器'
      }),
      isShowIcon: true,
      icon: 'icon-layout-force',
      isShowTooltip: true,
      tooltip: '',
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '46px',
      isVertical: true
    }
  }
}, {
  id: 'Initializer',
  name: $i18n.get({
    id: 'sdk.src.constants.template.Initializer',
    dm: '初始化器'
  }),
  props: {
    serviceId: 'GI/GI_SERVICE_INTIAL_GRAPH',
    schemaServiceId: 'GI/GI_SERVICE_SCHEMA',
    GI_INITIALIZER: true
  }
}, {
  id: 'PropertyGraphInitializer',
  type: 'AUTO',
  name: $i18n.get({
    id: 'sdk.src.constants.template.AttributeGraphCalculation',
    dm: '属性图计算'
  }),
  props: {}
}, {
  id: 'LayoutSwitch',
  name: $i18n.get({
    id: 'sdk.src.constants.template.LayoutSwitching',
    dm: '布局切换'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'sdk.src.constants.template.LayoutSwitching',
        dm: '布局切换'
      }),
      isShowIcon: true,
      icon: 'icon-layout',
      isShowTooltip: false,
      tooltip: $i18n.get({
        id: 'sdk.src.constants.template.SwitchCanvasLayoutWithOne',
        dm: '一键切换画布布局'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '46px',
      isVertical: true
    }
  }
}, {
  id: 'Toolbar',
  name: $i18n.get({
    id: 'sdk.src.constants.template.Toolbar',
    dm: '工具栏'
  }),
  props: {
    GI_CONTAINER: ['ZoomIn', 'ZoomOut', 'FitCenterView', 'LargeGraph', 'MapMode', 'ForceSimulation', 'LayoutSwitch', 'Export'],
    direction: 'vertical',
    placement: 'LT',
    offset: [24, 64]
  }
}, {
  id: 'Export',
  name: $i18n.get({
    id: 'sdk.src.constants.template.Export',
    dm: '导出'
  }),
  props: {
    GI_CONTAINER_INDEX: 2,
    GIAC: {
      visible: false,
      disabled: false,
      isShowTitle: false,
      title: $i18n.get({
        id: 'sdk.src.constants.template.Export',
        dm: '导出'
      }),
      isShowIcon: true,
      icon: 'icon-export',
      isShowTooltip: true,
      tooltip: $i18n.get({
        id: 'sdk.src.constants.template.ExportCsvPngJsonData',
        dm: '导出CSV,PNG,JSON数据'
      }),
      tooltipColor: '#3056e3',
      tooltipPlacement: 'right',
      hasDivider: false,
      height: '46px',
      isVertical: true
    }
  }
}];
export default {
  nodes: nodes,
  edges: edges,
  layout: layout,
  components: components
};