// 基于G6的activate-relations做了以下升级改造
// 1. 支持多选进行高亮
// 2. 支持分别定义高亮时上下游进行扩展的度数
import { throttle } from '@antv/util';
export default {
  getDefaultCfg: function getDefaultCfg() {
    return {
      // 可选 mouseenter || click
      // 选择 click 会监听 touch，mouseenter 不会监听
      trigger: 'mouseenter',
      activeState: 'active',
      inactiveState: 'inactive',
      resetSelected: false,
      multiSelectEnabled: true,
      modifierKey: 'alt',
      upstreamDegree: 1,
      downstreamDegree: 1,
      shouldUpdate: function shouldUpdate() {
        return true;
      }
    };
  },
  getEvents: function getEvents() {
    if (this.get('trigger') === 'mouseenter') {
      return {
        'node:mouseenter': 'setAllItemStates',
        'node:mouseleave': 'clearActiveState',
        'combo:mouseleave': 'clearActiveState',
        'canvas:click': 'clearActiveState',
        keyup: 'onKeyUp',
        keydown: 'onKeyDown'
      };
    }
    return {
      'node:click': 'setAllItemStates',
      'canvas:click': 'clearActiveState',
      'node:touchstart': 'setOnTouchStart',
      'combo:touchstart': 'setOnTouchStart',
      'canvas:touchstart': 'clearOnTouchStart',
      keyup: 'onKeyUp',
      keydown: 'onKeyDown'
    };
  },
  setOnTouchStart: function setOnTouchStart(e) {
    var self = this;
    try {
      var touches = e.originalEvent.touches;
      var event1 = touches[0];
      var event2 = touches[1];
      if (event1 && event2) {
        return;
      }
      e.preventDefault();
    } catch (e) {
      console.warn('Touch original event not exist!');
    }
    self.setAllItemStates(e);
  },
  clearOnTouchStart: function clearOnTouchStart(e) {
    var self = this;
    try {
      var touches = e.originalEvent.touches;
      var event1 = touches[0];
      var event2 = touches[1];
      if (event1 && event2) {
        return;
      }
      e.preventDefault();
    } catch (e) {
      console.warn('Touch original event not exist!');
    }
    self.clearActiveState(e);
  },
  setAllItemStates: function setAllItemStates(e) {
    //@ts-ignore
    clearTimeout(this.timer);
    this.throttleSetAllItemStates(e, this);
  },
  clearActiveState: function clearActiveState(e) {
    var _this = this;
    //@ts-ignore
    if (this.multiSelectEnabled && this.isModifierKeydown) {
      return;
    }
    // avoid clear state frequently, it costs a lot since all the items' states on the graph need to be cleared
    //@ts-ignore
    this.timer = setTimeout(function () {
      _this.throttleClearActiveState(e, _this);
    }, 50);
  },
  throttleSetAllItemStates: throttle(function (e, self) {
    var item = e.item;
    var graph = self.graph;
    if (!graph || graph.destroyed) return;
    self.item = item;
    if (!self.shouldUpdate(e.item, {
      event: e,
      action: 'activate'
    }, self)) {
      return;
    }
    var activeState = self.activeState;
    var inactiveState = self.inactiveState;
    var nodes = graph.getNodes();
    var combos = graph.getCombos();
    var edges = graph.getEdges();
    var vEdges = graph.get('vedges');
    var nodeLength = nodes.length;
    var comboLength = combos.length;
    var edgeLength = edges.length;
    var vEdgeLength = vEdges.length;
    var inactiveItems = self.inactiveItems || {};
    var activeItems = self.activeItems || {};
    var upstreamDegree = self.upstreamDegree || 1;
    var downstreamDegree = self.downstreamDegree || 1;
    if (!(self.multiSelectEnabled && self.isModifierKeydown)) {
      for (var i = 0; i < nodeLength; i++) {
        var node = nodes[i];
        var nodeId = node.getID();
        var hasSelected = node.hasState('selected');
        if (self.resetSelected) {
          if (hasSelected) {
            graph.setItemState(node, 'selected', false);
          }
        }
        if (activeItems[nodeId]) {
          graph.setItemState(node, activeState, false);
          delete activeItems[nodeId];
        }
        if (inactiveState && !inactiveItems[nodeId]) {
          graph.setItemState(node, inactiveState, true);
          inactiveItems[nodeId] = node;
        }
      }
      for (var _i = 0; _i < comboLength; _i++) {
        var combo = combos[_i];
        var comboId = combo.getID();
        var _hasSelected = combo.hasState('selected');
        if (self.resetSelected) {
          if (_hasSelected) {
            graph.setItemState(combo, 'selected', false);
          }
        }
        if (activeItems[comboId]) {
          graph.setItemState(combo, activeState, false);
          delete activeItems[comboId];
        }
        if (inactiveState && !inactiveItems[comboId]) {
          graph.setItemState(combo, inactiveState, true);
          inactiveItems[comboId] = combo;
        }
      }
      for (var _i2 = 0; _i2 < edgeLength; _i2++) {
        var edge = edges[_i2];
        var edgeId = edge.getID();
        if (activeItems[edgeId]) {
          graph.setItemState(edge, activeState, false);
          delete activeItems[edgeId];
        }
        if (inactiveState && !inactiveItems[edgeId]) {
          graph.setItemState(edge, inactiveState, true);
          inactiveItems[edgeId] = edge;
        }
      }
      for (var _i3 = 0; _i3 < vEdgeLength; _i3++) {
        var vEdge = vEdges[_i3];
        var vEdgeId = vEdge.getID();
        if (activeItems[vEdgeId]) {
          graph.setItemState(vEdge, activeState, false);
          delete activeItems[vEdgeId];
        }
        if (inactiveState && !inactiveItems[vEdgeId]) {
          graph.setItemState(vEdge, inactiveState, true);
          inactiveItems[vEdgeId] = vEdge;
        }
      }
    }
    var DirectionEnum;
    (function (DirectionEnum) {
      DirectionEnum["IN"] = "in";
      DirectionEnum["OUT"] = "out";
    })(DirectionEnum || (DirectionEnum = {}));
    var queue = [{
      item: item,
      direction: DirectionEnum.IN,
      degree: 0
    }, {
      item: item,
      direction: DirectionEnum.OUT,
      degree: 0
    }];
    // 按照upstreamDegree、downstreamDegree对应的上下游度数进行扩展，
    // 高亮扩展出来的点和边
    var _loop = function _loop() {
      var _queue$shift = queue.shift(),
        item = _queue$shift.item,
        direction = _queue$shift.direction,
        degree = _queue$shift.degree;
      if (item && !item.destroyed) {
        if (inactiveState && inactiveItems[item.getID()]) {
          graph.setItemState(item, inactiveState, false);
          delete inactiveItems[item.getID()];
        }
        if (!activeItems[item.getID()]) {
          graph.setItemState(item, activeState, true);
          activeItems[item.getID()] = item;
        }
        if (direction === DirectionEnum.IN && degree < upstreamDegree || direction === DirectionEnum.OUT && degree < downstreamDegree) {
          var _edges = direction === DirectionEnum.IN ? item.getInEdges() : item.getOutEdges();
          _edges.forEach(function (edge) {
            var edgeId = edge.getID();
            if (inactiveItems[edgeId]) {
              graph.setItemState(edge, inactiveState, false);
              delete inactiveItems[edgeId];
            }
            if (!activeItems[edgeId]) {
              graph.setItemState(edge, activeState, true);
              activeItems[edgeId] = edge;
            }
            edge.toFront();
          });
          item.getNeighbors(direction === DirectionEnum.IN ? 'source' : 'target').forEach(function (node) {
            queue.push({
              item: node,
              direction: direction,
              degree: degree + 1
            });
          });
        }
      }
    };
    while (queue.length > 0) {
      _loop();
    }
    self.activeItems = activeItems;
    self.inactiveItems = inactiveItems;
    graph.emit('afteractivaterelations', {
      item: e.item,
      action: 'activate'
    });
  }, 50, {
    trailing: true,
    leading: true
  }),
  throttleClearActiveState: throttle(function (e, self) {
    var graph = self.get('graph');
    if (!graph || graph.destroyed) return;
    if (!self.shouldUpdate(e.item, {
      event: e,
      action: 'deactivate'
    }, self)) return;
    var activeState = self.activeState;
    var inactiveState = self.inactiveState;
    var activeItems = self.activeItems || {};
    var inactiveItems = self.inactiveItems || {};
    //@ts-ignore
    Object.values(activeItems)
    //@ts-ignore
    .filter(function (item) {
      return !item.destroyed;
    }).forEach(function (item) {
      graph.clearItemStates(item, activeState);
    });
    //@ts-ignore
    Object.values(inactiveItems)
    //@ts-ignore
    .filter(function (item) {
      return !item.destroyed;
    }).forEach(function (item) {
      graph.clearItemStates(item, inactiveState);
    });
    self.activeItems = {};
    self.inactiveItems = {};
    graph.emit('afteractivaterelations', {
      item: e.item || self.get('item'),
      action: 'deactivate'
    });
  }, 50, {
    trailing: true,
    leading: true
  }),
  onKeyDown: function onKeyDown(e) {
    var self = this;
    if (!self.multiSelectEnabled) {
      return;
    }
    var code = e.key;
    if (!code) {
      return;
    }
    if (code.toLowerCase() === self.modifierKey.toLowerCase()) {
      self.isModifierKeydown = true;
    } else {
      self.isModifierKeydown = false;
    }
  },
  onKeyUp: function onKeyUp() {
    var self = this;
    self.isModifierKeydown = false;
  }
};