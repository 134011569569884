var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
var DEFAULT_CONNECT_INFO = {
  username: '',
  password: '',
  serverUrl: ''
};
export var getConnectInfo = function getConnectInfo() {
  try {
    var TUGRAPH_CONNECT_INFO_STRING = localStorage.getItem('TUGRAPH_CONNECT_INFO') || '{}';
    var _JSON$parse = JSON.parse(TUGRAPH_CONNECT_INFO_STRING),
      _JSON$parse$username = _JSON$parse.username,
      username = _JSON$parse$username === void 0 ? DEFAULT_CONNECT_INFO.username : _JSON$parse$username,
      _JSON$parse$password = _JSON$parse.password,
      password = _JSON$parse$password === void 0 ? DEFAULT_CONNECT_INFO.password : _JSON$parse$password,
      _JSON$parse$serverUrl = _JSON$parse.serverUrl,
      serverUrl = _JSON$parse$serverUrl === void 0 ? DEFAULT_CONNECT_INFO.serverUrl : _JSON$parse$serverUrl;
    return {
      username: username,
      password: password,
      serverUrl: serverUrl
    };
  } catch (error) {
    return DEFAULT_CONNECT_INFO;
  }
};
export var setConnectInfo = function setConnectInfo(params) {
  localStorage.setItem('TUGRAPH_CONNECT_INFO', JSON.stringify(params));
};
var formatProperties = function formatProperties(items) {
  return items.map(function (item) {
    var properties = item.properties,
      others = __rest(item, ["properties"]);
    var propertyMap = {};
    properties.forEach(function (property) {
      propertyMap[property.name] = property.type;
    });
    return Object.assign(Object.assign({}, others), {
      properties: propertyMap
    });
  });
};
export var formatGSSchema = function formatGSSchema(schema) {
  var nodes = schema.nodes,
    edges = schema.edges;
  return {
    nodes: formatProperties(nodes),
    edges: formatProperties(edges)
  };
};