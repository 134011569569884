function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export var formatProperties = function formatProperties(node) {
  var _a;
  if (node.data && ((_a = Object.keys(node.data)) === null || _a === void 0 ? void 0 : _a.length)) {
    // 如果有 data 属性，则取 data 属性和 nodeType
    var nodeType = node.nodeType,
      edgeType = node.edgeType;
    return nodeType ? Object.assign(Object.assign({}, node.data), {
      nodeType: nodeType
    }) : Object.assign(Object.assign({}, node.data), {
      edgeType: edgeType
    });
  }
  //@ts-ignore
  return node || {};
};
// lite version of lodash.get
function get(obj, path) {
  var pathArray = path.split('.');
  var result = obj;
  var _iterator = _createForOfIteratorHelper(pathArray),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var key = _step.value;
      if (result && key in result) {
        result = result[key];
      } else {
        return undefined;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return result;
}
var filterByExpression = function filterByExpression(data, expression) {
  if (!expression) return false;
  var _expression$name = expression.name,
    name = _expression$name === void 0 ? '' : _expression$name,
    operator = expression.operator,
    value = expression.value;
  var formatted = String(value);
  var propertyValue = String(get(data, name));
  switch (operator) {
    case 'eql':
      return propertyValue === formatted;
    case 'not-eql':
      return propertyValue !== formatted;
    case 'contain':
      return propertyValue.indexOf("".concat(formatted)) > -1;
    case 'not-contain':
      return propertyValue.indexOf("".concat(formatted)) === -1;
    case 'gt':
      return Number(propertyValue) > Number(value);
    case 'gte':
      return Number(propertyValue) >= Number(value);
    case 'lt':
      return Number(propertyValue) < Number(value);
    case 'lte':
      return Number(propertyValue) <= Number(value);
    default:
      return false;
  }
};
export var filterByTopRule = function filterByTopRule(item, rule) {
  var logic = rule.logic,
    expressions = rule.expressions;
  // 未配置规则一律通过
  if (!expressions || expressions.length === 0) {
    return true;
  }
  return logic === true ? expressions.every(function (exp) {
    return filterByExpression(formatProperties(item), exp);
  }) : expressions.some(function (exp) {
    return filterByExpression(formatProperties(item), exp);
  });
};
export var filterByRules = function filterByRules(items, rule) {
  return items.filter(function (item) {
    return filterByTopRule(item, rule);
  });
};