function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
var compatibleContainers = function compatibleContainers(containers) {
  /**
   * hack start
   *
   * 不应该修改 registerMeta 原有的containers 数据结构
   * 1. 先把追加的 GI_FreeContainer 移除
   * 2. 把 GI_CONTAINER 中的 数组对象 改为字符串,有的时候，修改容器内容，又会变成string
   *
   * TODO：
   * 需要在gi-site层修改这个containers的值
   *
   */
  return containers.filter(function (item) {
    if (item.id === 'GI_FreeContainer') {
      return false;
    }
    return true;
  }).map(function (item) {
    return Object.assign(Object.assign({}, item), {
      GI_CONTAINER: item.GI_CONTAINER.map(function (item) {
        if (item.value) {
          return item.value;
        }
        return item;
      })
    });
  });
  /** hack end */
};
/**
 * 获取根据容器资产配置，获取容器内资产实例
 *
 * @param context GISDK 上下文
 * @param containers 容器资产 props.containers
 * @returns
 */
var useContainer = function useContainer(context, _containers) {
  var assets = context.assets,
    config = context.config;
  var pageLayout = config.pageLayout;
  var containers = compatibleContainers(pageLayout.props.containers);
  var ComponentCfgMap = config.components.reduce(function (acc, curr) {
    return Object.assign(Object.assign({}, acc), _defineProperty({}, curr.id, curr));
  }, {});
  var getComponentById = function getComponentById(componentId) {
    var _a;
    if (!componentId) {
      return null;
    }
    var asset = assets.components[componentId];
    var assetConfig = ComponentCfgMap[componentId];
    if (!asset || !assetConfig) {
      console.warn("asset: ".concat(componentId, " not found"));
      return null;
    }
    var _ref = ((_a = assetConfig.props) === null || _a === void 0 ? void 0 : _a.GIAC_CONTENT) || asset.info,
      icon = _ref.icon;
    return {
      icon: icon,
      id: componentId,
      info: asset.info,
      props: assetConfig.props,
      component: asset.component
    };
  };
  return React.useMemo(function () {
    return containers.map(function (container) {
      var components = container.GI_CONTAINER.map(getComponentById).filter(function (c) {
        return c;
      });
      return Object.assign(Object.assign({}, container), {
        components: components
      });
    });
  }, [ComponentCfgMap, assets]);
};
export default useContainer;