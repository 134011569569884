function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import $i18n from '../i18n';
var aggregate = function aggregate(data) {
  var nodes = data.nodes,
    edges = data.edges;
  var edgeMap = new Map();
  edges.forEach(function (edge, index) {
    var source = edge.source,
      target = edge.target,
      edgeType = edge.edgeType;
    /** 汇总边 KEY */
    var key = "".concat(source, "->").concat(edgeType, "->").concat(target);
    var values = edgeMap.get(key);
    if (values) {
      // 为了和transform的edgeId逻辑保持一致，后续统一处理
      edgeMap.set(key, [].concat(_toConsumableArray(values), [Object.assign(Object.assign({}, edge), {
        id: edge.id || "".concat(source, "-").concat(target, "-").concat(index)
      })]));
    } else {
      edgeMap.set(key, [Object.assign(Object.assign({}, edge), {
        id: edge.id || "".concat(source, "-").concat(target, "-").concat(index)
      })]);
    }
  });
  var aggregateEdges = _toConsumableArray(edgeMap.keys()).map(function (key) {
    var children = edgeMap.get(key);
    var firstEdge = children[0];
    var source = firstEdge.source,
      target = firstEdge.target,
      edgeType = firstEdge.edgeType,
      edgeTypeKeyFromProperties = firstEdge.edgeTypeKeyFromProperties;
    var aggregate = children.length > 1;
    if (aggregate) {
      var _data;
      return {
        source: source,
        target: target,
        edgeType: edgeType,
        edgeTypeKeyFromProperties: edgeTypeKeyFromProperties,
        aggregate: children.map(function (item) {
          return Object.assign(Object.assign({}, item), {
            data: Object.assign(Object.assign({}, item.data), {
              GI_AGGREGATE_ID: key
            })
          });
        }),
        GI_AGGREGATE_ID: key,
        data: (_data = {
          source: source,
          target: target
        }, _defineProperty(_data, edgeTypeKeyFromProperties, edgeType), _defineProperty(_data, "aggregate", children), _defineProperty(_data, "aggregateCount", $i18n.get({
          id: 'sdk.src.process.aggregateEdges.SummaryChildrenlength',
          dm: '汇总：{childrenLength} 条'
        }, {
          childrenLength: children.length
        })), _data)
      };
    }
    return Object.assign({}, firstEdge);
  });
  return {
    nodes: nodes,
    edges: aggregateEdges
  };
};
export default aggregate;