import { extra } from '@antv/gi-sdk';
import info from './info';
export var SPLITOR = '|SPLITOR|';
export var ITEM_STATE;
(function (ITEM_STATE) {
  ITEM_STATE["Active"] = "active";
  ITEM_STATE["Default"] = "default";
  ITEM_STATE["Selected"] = "selected";
  ITEM_STATE["Disable"] = "disable";
  ITEM_STATE["Highlight"] = "highlight";
  ITEM_STATE["Inactive"] = "inactive";
})(ITEM_STATE || (ITEM_STATE = {}));
var GIAC_CONTENT_METAS = extra.GIAC_CONTENT_METAS,
  deepClone = extra.deepClone;
var metas = deepClone(GIAC_CONTENT_METAS);
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.title.default = info.name;
metas.GIAC_CONTENT.properties.GIAC_CONTENT.properties.icon.default = info.icon;
export default (function () {
  return Object.assign({}, metas);
});