function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { uniqueElementsBy } from './common';
/**
 *
 * @param assets 服务端拿到的资产：Elements
 * @param data 图数据
 * @returns
 */
var getElementsByAssets = function getElementsByAssets(assets, data, schemaData) {
  var nodeElements = {};
  var edgeElements = {};
  Object.keys(assets).forEach(function (key) {
    var element = assets[key];
    //@ts-ignore
    var info = element.info,
      registerMeta = element.registerMeta,
      registerShape = element.registerShape,
      registerTransform = element.registerTransform,
      defaultProps = element.defaultProps;
    var id = info.id,
      name = info.name,
      category = info.category,
      type = info.type;
    var elementType = type === 'NODE' ? 'nodes' : 'edges';
    var propertiesKey = schemaData[elementType].reduce(function (acc, curr) {
      var properties = curr.properties;
      var item = Object.keys(properties).map(function (c) {
        return {
          id: c,
          type: properties[c]
        };
      });
      return [].concat(_toConsumableArray(acc), _toConsumableArray(item));
    }, []);
    var keys = uniqueElementsBy(propertiesKey, function (a, b) {
      return a.id === b.id;
    });
    var configObj = registerMeta({
      data: data,
      keys: keys,
      schemaData: schemaData
    });
    /** 默认的配置值 */
    // const defaultProps = extractDefault({ config: configObj, value: {} });
    var item = Object.assign(Object.assign({}, element), {
      id: id,
      props: defaultProps,
      name: name,
      info: info,
      meta: configObj
    });
    if (elementType === 'nodes') {
      nodeElements = Object.assign(Object.assign({}, nodeElements), _defineProperty({}, id, item));
    }
    if (elementType === 'edges') {
      edgeElements = Object.assign(Object.assign({}, edgeElements), _defineProperty({}, id, item));
    }
  });
  return {
    nodes: nodeElements,
    edges: edgeElements
  };
};
export default getElementsByAssets;