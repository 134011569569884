import * as React from 'react';
import { bind } from 'size-sensor';
import { useContext } from './context';
var SizeSensor = function SizeSensor(props) {
  var _useContext = useContext(),
    GISDK_ID = _useContext.GISDK_ID,
    graph = _useContext.graph;
  React.useEffect(function () {
    var container = document.getElementById("".concat(GISDK_ID, "-graphin-container"));
    var unbind = bind(container, function (element) {
      if (element) {
        var clientHeight = element.clientHeight,
          clientWidth = element.clientWidth;
        graph.changeSize(clientWidth, clientHeight);
        graph.autoPaint();
      }
    });
    return function () {
      unbind();
    };
  }, [graph]);
  return null;
};
export default SizeSensor;