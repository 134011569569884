import { isEqual } from '@antv/util';
/**
 * 将当前 loop 检测结果和上一次的结果进行比较
 */
export function diffLoop(oldLoops, newLoops) {
  var diff = {
    keep: [],
    enter: [],
    exit: []
  };
  newLoops.forEach(function (newLoop) {
    if (!oldLoops.some(function (oldLoop) {
      return isEqual(oldLoop, newLoop);
    })) {
      diff.enter.push(newLoop);
    } else {
      diff.keep.push(newLoop);
    }
  });
  oldLoops.forEach(function (oldLoop) {
    if (!newLoops.some(function (newLoop) {
      return isEqual(oldLoop, newLoop);
    })) {
      diff.exit.push(oldLoop);
    }
  });
  return diff;
}
export function loopToEdges(loop) {
  var edges = [];
  for (var i = 0; i < loop.length - 1; i++) {
    edges.push([loop[i], loop[i + 1]]);
  }
  edges.push([loop[loop.length - 1], loop[0]]);
  return edges;
}
export function loopsToEdges(loops) {
  var edges = [];
  loops.forEach(function (loop) {
    for (var i = 0; i < loop.length - 1; i++) {
      edges.push([loop[i], loop[i + 1]]);
    }
    edges.push([loop[loop.length - 1], loop[0]]);
  });
  return edges;
}