import $i18n from '../i18n';
var info = {
  id: 'LargeGraph',
  name: $i18n.get({
    id: 'scene.src.LargeGraph.info.DBigPicture',
    dm: '3D大图'
  }),
  category: 'scene-analysis',
  desc: $i18n.get({
    id: 'scene.src.LargeGraph.info.EnableDViewAndRight',
    dm: '启用3D视图，可右键节点交互'
  }),
  icon: 'icon-3d',
  cover: 'http://xxxx.jpg',
  type: 'GIAC',
  docs: 'https://www.yuque.com/antv/gi/bhyhin'
};
export default info;