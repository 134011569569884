import $i18n from '../i18n';
var info = {
  id: 'NodesClustering',
  category: 'algorithm-analysis',
  type: 'GIAC_CONTENT',
  name: $i18n.get({
    id: 'gi-assets-algorithm.src.NodesClustering.info.NodeClustering',
    dm: '节点聚类'
  }),
  desc: $i18n.get({
    id: 'gi-assets-algorithm.src.NodesClustering.info.UseKMeansAlgorithmTo',
    dm: '使用 K-Means 算法计算节点的聚类'
  }),
  icon: 'icon-Kjunzhijulei',
  cover: 'http://xxxx.jpg',
  docs: 'https://www.yuque.com/antv/gi/gcmtuhktnogm5pae'
};
export default info;