import $i18n from '../../i18n';
var info = {
  id: 'SegmentedLayout',
  name: $i18n.get({
    id: 'basic.components.SegmentedLayout.info.SegmentLayout',
    dm: '分段布局'
  }),
  desc: $i18n.get({
    id: 'basic.components.SegmentedLayout.info.SectionalizerLayout',
    dm: '分段器布局'
  }),
  icon: 'icon-pic-center',
  cover: 'http://xxxx.jpg',
  category: 'container-components',
  type: 'GICC_LAYOUT',
  docs: 'https://www.yuque.com/antv/gi/dfrh7gwyyvg2wxz9'
};
export default info;