import { ConfigProvider } from 'antd';
import { useContext } from 'react';
var usePrefixCls = function usePrefixCls(tag, props) {
  var _a;
  if ('ConfigContext' in ConfigProvider) {
    // @ts-ignore
    var _useContext = useContext(ConfigProvider.ConfigContext),
      getPrefixCls = _useContext.getPrefixCls;
    return getPrefixCls(tag, props === null || props === void 0 ? void 0 : props.prefixCls);
  } else {
    var prefix = (_a = props === null || props === void 0 ? void 0 : props.prefixCls) !== null && _a !== void 0 ? _a : 'ant-';
    return "".concat(prefix).concat(tag !== null && tag !== void 0 ? tag : '');
  }
};
export default usePrefixCls;