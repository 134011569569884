var contextKey = 'GI_SITE_CONTEXT';
/**
 * 获取站点信息上下文
 * @returns
 */
export var getSiteContext = function getSiteContext() {
  var defaltContext = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    GI_SITE_ID: 'DEFAULT'
  };
  return window[contextKey] || defaltContext;
};
/**
 * 设置站点信息上下文
 * @returns
 */
export var setSiteContext = function setSiteContext(context) {
  var preContext = window[contextKey] || {};
  window[contextKey] = Object.assign(Object.assign({}, preContext), context);
};