/**
 * 提取 Markdown 中的代码块
 * @param content
 */
export function extractCodeBlocks(markdown) {
  var codeBlockRegex = /```([a-zA-Z]+)?(?:\n)([\s\S]*?)```/g;
  var codeBlocks = [];
  var match;
  while ((match = codeBlockRegex.exec(markdown)) !== null) {
    var language = match[1] ? match[1] : 'unknown';
    var code = match[2].replace(/\n/g, ' ').trim();
    codeBlocks.push({
      language: language,
      code: code
    });
  }
  return codeBlocks;
}