import $i18n from '../../i18n';
var info = {
  id: 'SubGraphLayout',
  name: $i18n.get({
    id: 'advance.components.SubGraphLayout.info.SubgraphLayout',
    dm: '子图布局'
  }),
  category: 'data-analysis',
  desc: $i18n.get({
    id: 'advance.components.SubGraphLayout.info.SubgraphLayoutPanel',
    dm: '子图布局面板'
  }),
  cover: 'http://xxxx.jpg',
  type: 'GIAC_CONTENT',
  icon: 'icon-branches',
  docs: 'https://www.yuque.com/antv/gi/yfg1clqzztqvcnt2'
};
export default info;