function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { GraphinContext } from '@antv/graphin';
import React from 'react';
//修复异常数据
var fixNodePosition = function fixNodePosition(graph, graphData) {
  var _a, _b;
  var currentData = graph.save();
  var nodeMap = {};
  (_a = currentData.nodes) === null || _a === void 0 ? void 0 : _a.forEach(function (n) {
    nodeMap[n.id] = n;
  });
  var nodes = (_b = graphData.nodes) === null || _b === void 0 ? void 0 : _b.map(function (n) {
    if (typeof n.x !== 'number' || typeof n.y !== 'number') {
      var node = nodeMap[n.id];
      if (node) {
        return Object.assign(Object.assign({}, n), {
          x: node.x,
          y: node.y
        });
      } else {
        return Object.assign(Object.assign({}, n), {
          x: 0,
          y: 0
        });
      }
    }
    return n;
  });
  return Object.assign(Object.assign({}, graphData), {
    nodes: nodes
  });
};
var useRedoUndo = function useRedoUndo() {
  var _React$useContext = React.useContext(GraphinContext),
    graph = _React$useContext.graph;
  var _React$useState = React.useState(function () {
      return {
        undoStack: graph.getUndoStack(),
        redoStack: graph.getRedoStack()
      };
    }),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    stackInfo = _React$useState2[0],
    setStackInfo = _React$useState2[1];
  var redo = function redo() {
    var redoStack = graph.getRedoStack();
    if (!redoStack || redoStack.length === 0) {
      return;
    }
    var currentData = redoStack.pop();
    if (currentData) {
      var action = currentData.action;
      var data = currentData.data.after;
      graph.pushStack(action, Object.assign(Object.assign({}, currentData.data), {
        after: fixNodePosition(graph, currentData.data.after),
        before: fixNodePosition(graph, currentData.data.before)
      }));
      if (action === 'delete') {
        data = currentData.data.before;
      }
      update(action, data);
    }
  };
  var undo = function undo() {
    var undoStack = graph.getUndoStack();
    if (!undoStack || undoStack.length === 1) {
      return;
    }
    var currentData = undoStack.pop();
    if (currentData) {
      var action = currentData.action;
      graph.pushStack(action, Object.assign(Object.assign({}, currentData.data), {
        after: fixNodePosition(graph, currentData.data.after),
        before: fixNodePosition(graph, currentData.data.before)
      }), 'redo');
      var data = currentData.data.before;
      if (action === 'add') {
        data = currentData.data.after;
      }
      update(action, data);
    }
  };
  var update = function update(action, data) {
    if (!data) return;
    switch (action) {
      case 'visible':
        {
          Object.keys(data).forEach(function (key) {
            var array = data[key];
            if (!array) return;
            array.forEach(function (model) {
              var item = graph.findById(model.id);
              if (!item) {
                return;
              }
              if (model.visible) {
                graph.showItem(item, false);
              } else {
                graph.hideItem(item, false);
              }
            });
          });
          break;
        }
      case 'render':
      case 'update':
        var nodeMap = graph.getNodes().reduce(function (map, node) {
          map[node.getID()] = node;
          return map;
        }, {});
        Object.keys(data).forEach(function (key) {
          var array = data[key];
          if (!array) return;
          array.forEach(function (model) {
            if (nodeMap[model.id]) {
              graph.updateItem(model.id, model, false);
            }
          });
        });
        break;
      case 'changedata':
        graph.changeData(data, false);
        break;
      case 'delete':
        {
          Object.keys(data).forEach(function (key) {
            var array = data[key];
            if (!array) return;
            array.forEach(function (model) {
              var itemType = model.itemType;
              delete model.itemType;
              graph.addItem(itemType, model, false);
            });
          });
          break;
        }
      case 'add':
        Object.keys(data).forEach(function (key) {
          var array = data[key];
          if (!array) return;
          array.forEach(function (model) {
            graph.removeItem(model.id, false);
          });
        });
        break;
      case 'updateComboTree':
        var comboMap = {};
        graph.getCombos().forEach(function (combo) {
          comboMap[combo.getID()] = combo;
        });
        Object.keys(data).forEach(function (key) {
          var array = data[key];
          if (!array) return;
          array.forEach(function (model) {
            if (!comboMap[model.id] || model.parentId && !comboMap[model.parentId]) {
              return;
            }
            graph.updateComboTree(model.id, model.parentId, false);
          });
        });
        break;
      default:
    }
  };
  React.useEffect(function () {
    var handleStackChanage = function handleStackChanage(evt) {
      var undoStack = evt.undoStack,
        redoStack = evt.redoStack;
      setStackInfo({
        undoStack: undoStack,
        redoStack: redoStack
      });
    };
    graph.on('stackchange', handleStackChanage);
    return function () {
      graph.off('stackchange', handleStackChanage);
    };
  }, [graph]);
  //@ts-ignore
  return Object.assign(Object.assign(Object.assign({}, stackInfo), {
    redo: redo,
    undo: undo
  }), stackInfo);
};
export default useRedoUndo;