import { Parser } from 'json2csv';
import $i18n from '../../i18n';
var downloadCsv = function downloadCsv(data) {
  var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : $i18n.get({
    id: 'basic.components.common.downloadCsv.Unnamed',
    dm: '未命名'
  });
  // 数量过多，结果下载成 csv 文件
  var json2csvParser = new Parser({
    delimiter: ',',
    withBOM: true
  });
  var csvStr = json2csvParser.parse(data);
  var a = document.createElement('a');
  a.href = 'data:text/csv;charset=utf-8,' + encodeURI("".concat(csvStr));
  a.download = "".concat(name, ".csv");
  a.click();
};
export default downloadCsv;