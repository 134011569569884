import Graphin from '@antv/graphin';
var registeredShapes = '';
var registeredLayouts = '';
export var registerShapes = function registerShapes(Elements) {
  if (Elements) {
    var nextShapes = Object.keys(Elements).join('-');
    var prevShapes = registeredShapes;
    if (nextShapes !== prevShapes) {
      Object.keys(Elements).forEach(function (type) {
        Elements[type].registerShape(Graphin);
      });
      registeredShapes = nextShapes;
    }
  }
};
export var registerLayouts = function registerLayouts(Layouts) {
  if (Layouts) {
    var nextLayout = Object.keys(Layouts).join('-');
    var prevLayout = registeredLayouts;
    if (nextLayout !== prevLayout) {
      Object.keys(Layouts).forEach(function (type) {
        Layouts[type].registerLayout(Graphin);
      });
      registeredLayouts = nextLayout;
    }
  }
};
export { registerIconFonts } from '@antv/gi-common-components';