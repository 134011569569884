function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var findAllPath = function findAllPath(graphData, start, end, directed) {
  if (start === end) {
    var _edges = graphData.edges.filter(function (edge) {
      return edge.source === start && edge.target === end;
    });
    var allNodePath = new Array(_edges.length).fill([start, start]);
    var _allEdgePath = _edges.map(function (edge) {
      return [edge.id];
    });
    return {
      allNodePath: allNodePath,
      allEdgePath: _allEdgePath
    };
  }
  var _graphData$edges = graphData.edges,
    edges = _graphData$edges === void 0 ? [] : _graphData$edges;
  var visited = [start];
  // 与节点绑定的边
  var edgePath = [];
  var isVisited = _defineProperty({}, start, true);
  var stack = []; // 辅助栈，用于存储访问过的节点的邻居节点
  var allPath = [];
  var allEdgePath = [];
  var neighbors = directed ? getNeighbors(start, edges, 'target') : getNeighbors(start, edges);
  stack.push(neighbors);
  while (visited.length > 0 && stack.length > 0) {
    var children = stack[stack.length - 1];
    if (children.length) {
      var child = children.shift();
      if (child) {
        visited.push(child.node);
        edgePath.push(child.edge);
        isVisited[child.node] = true;
        neighbors = directed ? getNeighbors(child.node, edges, 'target') : getNeighbors(child.node, edges);
        stack.push(neighbors.filter(function (neighbor) {
          return !isVisited[neighbor.node];
        }));
      }
    } else {
      var node = visited.pop();
      edgePath.pop();
      isVisited[node] = false;
      stack.pop();
      continue;
    }
    if (visited[visited.length - 1] === end) {
      var path = visited.map(function (node) {
        return node;
      });
      allPath.push(path);
      allEdgePath.push([].concat(edgePath));
      var _node = visited.pop();
      edgePath.pop();
      isVisited[_node] = false;
      stack.pop();
    }
  }
  return {
    allNodePath: allPath,
    allEdgePath: allEdgePath
  };
};
export var getNeighbors = function getNeighbors(nodeId) {
  var edges = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var type = arguments.length > 2 ? arguments[2] : undefined;
  var currentEdges = edges.filter(function (edge) {
    return edge.source === nodeId || edge.target === nodeId;
  });
  if (type === 'target') {
    // 当前节点为 source，它所指向的目标节点
    var _neighhborsConverter = function _neighhborsConverter(edge) {
      return edge.source === nodeId;
    };
    return currentEdges.filter(_neighhborsConverter).map(function (edge) {
      return {
        node: edge.target,
        edge: edge.id
      };
    });
  }
  if (type === 'source') {
    // 当前节点为 target，它所指向的源节点
    var _neighhborsConverter2 = function _neighhborsConverter2(edge) {
      return edge.target === nodeId;
    };
    return currentEdges.filter(_neighhborsConverter2).map(function (edge) {
      return {
        node: edge.source,
        edge: edge.id
      };
    });
  }
  // 若未指定 type ，则返回所有邻居
  var neighhborsConverter = function neighhborsConverter(edge) {
    return edge.source === nodeId ? {
      node: edge.target,
      edge: edge.id
    } : {
      node: edge.source,
      edge: edge.id
    };
  };
  return currentEdges.map(neighhborsConverter);
};